import React from "react";
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Col} from "reactstrap";
import {Pie} from "react-chartjs-2";
import {dashboardEmailStatisticsChart} from "../../variables/charts";


function PieChart(props) {

    return (
        <>
            <Col md="12">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className=" text-info"> <i className="fa fa-check text-info"/>  &nbsp; {props.title}</CardTitle>
                    </CardHeader>
                    <CardBody style={{height: "466px"}}>
                        <Pie
                            data={(canvas) => {
                                return {
                                    labels: [1, 2, 3],
                                    datasets: [
                                        {
                                            label: "Gender",
                                            pointRadius: 0,
                                            pointHoverRadius: 0,
                                            backgroundColor: props.colorChart,
                                            borderWidth: 0,
                                            data: props.dataChart,
                                        },
                                    ],
                                };
                            }}
                            options={dashboardEmailStatisticsChart.options}
                        />
                    </CardBody>
                    <CardFooter>
                        <hr/>
                        <div className="legend" style={{overflowX: 'auto'}}>

                        {/*Total Active User : {props.totalUsers}<br/>*/}
                            {props.property.map((prop,index) =>{
                                return (
                                    <span key={index}><i className=" text-info"> {index+1} &nbsp;:&nbsp;&nbsp; </i>
                                <i className="fa fa-circle " style={{color:props.colorChart[index]}}/> &nbsp;
                                {prop.props.children[3]} : {prop.props.children[5]} &nbsp;<br/>
                                </span>
                                );
                            })}
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </>
    );
}

export default PieChart;
