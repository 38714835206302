import React, {useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common.js";


import {convertToRaw} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import {Editor} from "react-draft-wysiwyg";

function AddDetailStory(props) {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addDetailStory = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addStoryDetail", {
            id: e.target.id.value,
            story_id: e.target.story_id.value,
            detail:draftToHtml(convertToRaw(props.editorState.getCurrentContent())),
            detail_fa:draftToHtml(convertToRaw(props.editorStateFa.getCurrentContent())),
            detail_pa:draftToHtml(convertToRaw(props.editorStatePa.getCurrentContent())),
        }, {headers}).then(res => {
            props.setDetailShowModal(false);
        }).catch(function (error) {
            props.setDetailShowModal(false);
        });
    };
    function onEditorStateChange(editorState) {
        props.setEditorState(editorState);
    };

    function onEditorStateChangeFa(editorState) {
        props.setEditorStateFa(editorState);
    };

    function onEditorStateChangePa(editorState) {
        props.setEditorStatePa(editorState);
    };
    return (
        <>
            <Modal show={props.detailShowModal} backdrop="static" size="xl" onHide={() => props.setDetailShowModal(false)}>
                <Form onSubmit={(e) => {
                    document.getElementById("submit").disabled = true;
                    addDetailStory(e)
                }}>
                    <div className="hideInput">
                        <Input type="text" className="hidden" required name="id" id="id"
                               defaultValue={props.recordId}/>
                        <Input type="text" name="story_id" id="story_id" defaultValue={props.storyId}/>
                    </div>

                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile text-info">
                             Detail Story
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="pr-3" md="10">
                                <FormGroup>
                                    <br/>
                                    <label>Description : </label>
                                    <div className=" border border-1 border-dark text-info" >
                                        <Editor
                                            className="bg-danger"
                                            editorState={props.editorState}
                                            toolbarClassName="toolbarClassName text-info"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                        <textarea className="hideInput" name="description" id="description"
                                                  defaultValue={draftToHtml(convertToRaw(props.editorState.getCurrentContent()))}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row>
                            <Col className="pr-3" md="10">
                                <FormGroup>
                                    <br/>
                                    <label>Description Farsi: </label>
                                    <div className=" border border-1 border-dark text-info" >
                                        <Editor
                                            className="bg-danger"
                                            editorState={props.editorStateFa}
                                            toolbarClassName="toolbarClassName text-info"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChangeFa}
                                        />
                                        <textarea className="hideInput" name="description" id="description"
                                                  defaultValue={draftToHtml(convertToRaw(props.editorStateFa.getCurrentContent()))}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        {/*pashto*/}
                        <Row>
                            <Col className="pr-3" md="10">
                                <FormGroup>
                                    <br/>
                                    <label>Description Pashto: </label>
                                    <div className=" border border-1 border-dark text-info" >
                                        <Editor
                                            className="bg-danger"
                                            editorState={props.editorStatePa}
                                            toolbarClassName="toolbarClassName text-info"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChangePa}
                                        />
                                        <textarea className="hideInput" name="description_pa" id="description_pa"
                                                  defaultValue={draftToHtml(convertToRaw(props.editorStatePa.getCurrentContent()))}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Modal.Body>

                    <div className="modal-footer">
                        <Button title="Close"
                                className="btn-simple"
                                type="button"
                                variant="link"
                                onClick={() => props.setDetailShowModal(false)}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" id="submit" type="submit">
                            Add
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
}

export default AddDetailStory;
