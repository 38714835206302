// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import Axios from "axios";
import {dataURLtoFile, mainUrl, toBase64} from "../../common/common";
import {Button, Col, Dropdown, Form, FormGroup, Modal, Row, Spinner, Table} from "react-bootstrap";
import toast from "react-hot-toast";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";

export default function WebSetting() {

    const [setup, setSetup] = useState({
        "id":"",
        "logo":"",
        "phone":"",
        "email":"",
        "address":"",
        "app_store_link":"",
        "play_store_link":"",
        "facebook_link":"",
        "twitter":"",
        "instagram_link":"",
        "youtube_link":"",
        "linkedin_link":"",
        "tiktok_link":"",
        "whatsapp_link":""
    });


    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getSetup", {headers});
        const lists = data;
        setSetup(lists);
    };
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addWeb = async (e) => {
        e.preventDefault();
        const f = e.target;
        let attachFile = "";
        if (f.logo.files[0] != null && f.logo.files[0] != "") {
            attachFile = await toBase64(f.logo.files[0]);
        }
        await Axios.post(mainUrl + "addWeb", {
            id: f.id.value,
            logo: attachFile,
            phone: f.phone.value,
            email: f.email.value,
            address: f.address.value,
            app_store_link: f.app_store_link.value,
            play_store_link: f.play_store_link.value,
            facebook_link: f.facebook_link.value,
            twitter: f.twitter.value,
            instagram_link: f.instagram_link.value,
            youtube_link: f.youtube_link.value,
            tiktok_link: f.tiktok_link.value,
            linkedin_link: f.linkedin_link.value,
            whatsapp_link: f.whatsapp_link.value

        }, {headers}).then(res => {
            setSetup(res.data);
            console.log(setup);
            if (f.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
        }).catch(function (error) {
            notifyError(error);
        });
    };


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="p-4">
                <form onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }} onSubmit={(e) => {
                    addWeb(e)
                }}>
                    <div className="row">
                        <input name="id" defaultValue={setup.id} className="form-control" type="hidden"/>
                        <div className="col-md-4">
                            <label>Logo</label>
                            <input name="logo"  defaultValue={setup.logo} className="form-control" type="file"/>
                            Select .JPG file only.<br/>
                            Maximum upload file size :0.5 MB <br/>
                            (height: 100px) X (width: auto) <br/>
                            Resize Image:  <a href="https://www.resizepixel.com/" target="_blank">resizepixel.com</a>
                        </div>

                        <div className="col-md-4">
                            <label>Phone Number</label>
                            <input name="phone" defaultValue={setup.phone} className="form-control" type="phone"/>
                        </div>

                        <div className="col-md-4">
                            <label>Email Address</label>
                            <input name="email" defaultValue={setup.email} className="form-control" type="email"/>
                        </div>
                        <div className="col-md-4">
                            <label>Address</label>
                            <input name="address" defaultValue={setup.address} className="form-control" type="text"/>
                        </div>
                        <div className="col-md-4">
                            <label>App Store Link</label>
                            <input name="app_store_link" defaultValue={setup.app_store_link} className="form-control" type="text"/>
                        </div>
                        <div className="col-md-4">
                            <label>Play Store Link</label>
                            <input name="play_store_link" defaultValue={setup.play_store_link} className="form-control" type="text"/>
                        </div>
                        <div className="col-md-4">
                            <label>Facebook Link</label>
                            <input name="facebook_link" defaultValue={setup.facebook_link} className="form-control" type="text"/>
                        </div>

                        <div className="col-md-4">
                            <label>Twitter Link</label>
                            <input name="twitter" defaultValue={setup.twitter} className="form-control" type="text"/>
                        </div>


                        <div className="col-md-4">
                            <label>Instagram Link</label>
                            <input name="instagram_link" defaultValue={setup.instagram_link} className="form-control" type="text"/>
                        </div>
                        <div className="col-md-4">
                            <label>Youtube Link</label>
                            <input name="youtube_link" defaultValue={setup.youtube_link} className="form-control" type="text"/>
                        </div>
                        <div className="col-md-4">
                            <label>Linkedin Link</label>
                            <input name="linkedin_link" defaultValue={setup.linkedin_link} className="form-control" type="text"/>
                        </div>
                        <div className="col-md-4">
                            <label>TikTok Link</label>
                            <input name="tiktok_link" defaultValue={setup.tiktok_link} className="form-control" type="text"/>
                        </div>
                        <div className="col-md-4">
                            <label>WhatsApp Phone Number</label>
                            <input name="whatsapp_link" defaultValue={setup.whatsapp_link} className="form-control" type="text"/>
                        </div>

                    </div>
                    <div className=" p-5 ml-end">
                        <button className="form-control btn btn-info">Save</button>
                    </div>
                </form>
            </div>
        </>
    );

};
