// @flow
import * as React from 'react';
import Axios from "axios";
import {Button, Col, Dropdown, Form, FormGroup, Modal, Row, Spinner, Table} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {mainUrl} from "../../../common/common";

export default function AboutUs(props) {


    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const editPage = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "editPage", {
            page_name: 'about',
            data:draftToHtml(convertToRaw(props.editorState.getCurrentContent())),
            data_fa:draftToHtml(convertToRaw(props.editorStateFa.getCurrentContent())),
            data_pa:draftToHtml(convertToRaw(props.editorStatePa.getCurrentContent())),
        }, {headers}).then(res => {
            props.setAboutShowModal(false);
        }).catch(function (error) {
            props.setAboutShowModal(false);
        });
    };
    function onEditorStateChange(editorState) {
        props.setEditorState(editorState);
    };
    function onEditorStateChangeFa(editorState) {
        props.setEditorStateFa(editorState);
    };
    function onEditorStateChangePa(editorState) {
        props.setEditorStatePa(editorState);
    };
    return (
        <>
            <Modal show={props.aboutShowModal} backdrop="static" size="xl" onHide={() => props.setAboutShowModal(false)}>
                <Form onSubmit={(e) => {
                    document.getElementById("submit").disabled = true;
                    editPage(e)
                }}>

                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile text-info">
                           About
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="pr-3" md="10">
                                <FormGroup>
                                    <br/>
                                    <label>Description : </label>
                                    <div className=" border border-1 border-dark text-info" >
                                        <Editor
                                            className="bg-danger"
                                            editorState={props.editorState}
                                            toolbarClassName="toolbarClassName text-info"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                        <textarea className="hideInput" name="description" id="description"
                                                  defaultValue={draftToHtml(convertToRaw(props.editorState.getCurrentContent()))}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        {/*farsi*/}
                        <Row>
                            <Col className="pr-3" md="10">
                                <FormGroup>
                                    <br/>
                                    <label>Description Farsi: </label>
                                    <div className=" border border-1 border-dark text-info" >
                                        <Editor
                                            className="bg-danger"
                                            editorState={props.editorStateFa}
                                            toolbarClassName="toolbarClassName text-info"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChangeFa}
                                        />
                                        <textarea className="hideInput" name="description" id="description_fa"
                                                  defaultValue={draftToHtml(convertToRaw(props.editorStateFa.getCurrentContent()))}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        {/*pashto*/}
                        <Row>
                            <Col className="pr-3" md="10">
                                <FormGroup>
                                    <br/>
                                    <label>Description Pashto: </label>
                                    <div className=" border border-1 border-dark text-info" >
                                        <Editor
                                            className="bg-danger"
                                            editorState={props.editorStatePa}
                                            toolbarClassName="toolbarClassName text-info"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChangePa}
                                        />
                                        <textarea className="hideInput" name="description" id="descriptionPa"
                                                  defaultValue={draftToHtml(convertToRaw(props.editorStatePa.getCurrentContent()))}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Modal.Body>

                    <div className="modal-footer">
                        <Button title="Close"
                                className="btn-simple"
                                type="button"
                                variant="link"
                                onClick={() => props.setAboutShowModal(false)}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" id="submit" type="submit">
                            Add
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};
