import React, {useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common.js";


import {convertToRaw} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import ImageSelect from "./utils/imageSelect";
import image from "../../assets/img/photo.png";

function AddStory(props) {
    const [showUploadImage, setShowUploadImage] = useState(false);
    const [localImageStory, setLocalImageStory] = useState("");

    useEffect(() => {
        setTimeout(() => setLocalImageStory(sessionStorage.getItem("storyImage")), 1000);
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addStory = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addStory", {
            id: e.target.storyIds.value,
            image: e.target.image.value,
            title: e.target.title.value,
            description: e.target.description.value,
            title_fa: e.target.title_fa.value,
            description_fa: e.target.description_fa.value,
            title_pa: e.target.title_pa.value,
            description_pa: e.target.description_pa.value,




        }, {headers}).then(res => {

            props.setShowModal(false);
        }).catch(function (error) {
            props.setShowModal(false);
        });
    };

    return (
        <>
            {showUploadImage == true && <ImageSelect   setShowUploadImage={setShowUploadImage} setLocalImageStory={setLocalImageStory}/>}

            <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
                <Form onSubmit={(e) => {
                    document.getElementById("submit").disabled = true;
                    addStory(e)
                }}>
                    <div className="hideInput">
                        <Input type="text" className="hidden" required name="storyIds" id="storyIds"/>
                        <Input type="text" name="image" id="image" defaultValue={localImageStory}/>
                    </div>
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile text-info">
                            New Story
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="pr-3">
                            <Col className="pr-3" md="6">
                                <FormGroup>
                                    <label>Title <span className="text text-danger">*</span></label>
                                    <Input required name="title" id="title"
                                           placeholder="Title" title="Title" type="text"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pr-3" md="12">
                                <FormGroup>
                                    <label>Description <span className="text text-danger">*</span></label>
                                    <br/>
                                    <textarea required row="10" className="form-control"
                                              style={{maxHeight: "500px!important"}} name="description"
                                              title="Description" id="description"
                                              placeholder="Description" type="text">
                                        </textarea>
                                </FormGroup>
                            </Col>
                        </Row>

<hr/>
                        {/*farsi*/}
                        <Row className="pr-3">
                            <Col className="pr-3" md="6">
                                <FormGroup>
                                    <label>Title Farsi <span className="text text-danger">*</span></label>
                                    <Input required name="title_fa" id="title_fa"
                                           placeholder="Title" title="Title" type="text"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pr-3" md="12">
                                <FormGroup>
                                    <label>Description Farsi<span className="text text-danger">*</span></label>
                                    <br/>
                                    <textarea required row="10" className="form-control"
                                              style={{maxHeight: "500px!important"}} name="description_fa"
                                              title="Description" id="description_fa"
                                              placeholder="Description" type="text">
                                        </textarea>
                                </FormGroup>
                            </Col>
                        </Row>
<hr/>
                        {/*pashto*/}
                        <Row className="pr-3">
                            <Col className="pr-3" md="6">
                                <FormGroup>
                                    <label>Title Pashto<span className="text text-danger">*</span></label>
                                    <Input required name="title_pa" id="title_pa"
                                           placeholder="Title" title="Title" type="text"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pr-3" md="12">
                                <FormGroup>
                                    <label>Description Pashto <span className="text text-danger">*</span></label>
                                    <br/>
                                    <textarea required row="10" className="form-control"
                                              style={{maxHeight: "500px!important"}} name="description_pa"
                                              title="Description" id="description_pa"
                                              placeholder="Description" type="text">
                                        </textarea>
                                </FormGroup>
                            </Col>
                        </Row>




                        <Row>
                            <Col>
                                <div className="text-center">
                                    <div
                                        style={{position: "relative", textAlign: "center", color: "white"}} alt="Image "
                                        title="Image">
                                        <img src={localImageStory != "" ? localImageStory : image}
                                             style={{
                                                 borderRadius: "5%",
                                                 backgroundColor: "#8080805e!important",
                                                 cursor: "pointer"
                                             }}
                                             onClick={() => {
                                                 if (!showUploadImage) {
                                                     setShowUploadImage(true);
                                                 } else {
                                                     setShowUploadImage(false);
                                                 }
                                             }} width="400" height="400"/>
                                        <div style={{
                                            position: "absolute",
                                            top: "85%",
                                            left: "50%",
                                            transform: "translate(-50%, 50%)",
                                            whiteSpace: "nowrap"
                                        }} className="text-primary">Select Image <br/> (400px) X (400px) <br/> Resize Image: <a href="https://www.resizepixel.com/" target="_blank">resizepixel.com</a>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <div className="modal-footer">
                        <Button title="Close"
                                className="btn-simple"
                                type="button"
                                variant="link"
                                onClick={() => props.setShowModal(false)}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" id="submit" type="submit">
                            Add
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
}

export default AddStory;
