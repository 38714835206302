import Axios from "axios";
import {mainUrl} from "../../common/common";
import {countryList} from "../../common/CountryList";
import {CardBody} from "reactstrap";
import {Button, Col, FormGroup, Row} from "react-bootstrap";
import React, {useState} from "react";
import Select from "react-select";

// import {useEffect} from "preact";

function SearchCustomer(props) {
    const [selectedOption, setSelectedOption] = useState(null);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const searchCustomer = async (e) => {
        e.preventDefault();
        const f = e.target;
        props.setSearchParameter({
            'country': f.country.value,
            'phone': f.phone.value,
            'email': f.email.value,
            'name': f.name.value
        });
        props.setSearchStatus(true);
        const {data} = await Axios.post(mainUrl + "searchCustomer?page=1",
            {
                'country': f.country.value,
                'phone': f.phone.value,
                'email': f.email.value,
                'name': f.name.value
            }, {headers});
        const lists = data;
        props.setList(lists);
    };

    return (
        <>
            <CardBody className="mr-4 ml-4">
                <form onSubmit={(e) => searchCustomer(e)}>
                    <Row>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label> Customer Name <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Customer Name" name="name" id="name"
                                       placeholder="Customer Name" type="text"/>
                            </FormGroup>
                        </Col>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label> Email <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Email Address" name="email" id="email"
                                       placeholder="Email Address" type="email"/>
                            </FormGroup>
                        </Col>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label>Phone <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Phone Number" name="phone" id="phone"
                                       placeholder="Phone Number" type="text"/>
                            </FormGroup>
                        </Col>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label>Country <span
                                    className="text text-danger">*</span></label>
                                <Select
                                    name="country" id="country"
                                    onChange={setSelectedOption}
                                    options={countryList}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <div className="ml-end">
                            <Button title="Search" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Search
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </form>
            </CardBody>
        </>
    );
}


export default SearchCustomer;
