import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Dropdown, Row, Spinner, Table} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

function ListCustomer(props) {

    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        setRecordList(props.list.data);
    },[props.list])

    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchCustomer?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getCustomer?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

        setRecordId(0);
    }

    let rowNo = 1;
    return (
        <>
            <Row>
                <Col md="12">
                    <Card className="card-">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2>Customer List</h2>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>

                        <CardBody>
                            <Table>
                                <thead className="text-info">
                                <tr>
                                    <th>No</th>
                                    <th style={{width: "10%"}}>Name</th>
                                    <th style={{width: "10%"}}>Last Name</th>
                                    <th style={{width: "10%"}}>Country</th>
                                    <th style={{width: "10%"}}>Email</th>
                                    <th style={{width: "10%"}}>Phone</th>
                                    <th style={{width: "50%"}}>Address</th>
                                    {/*<th className="text-right">Action</th>*/}
                                </tr>
                                </thead>

                                {props.isLoading === false ? (<tbody>
                                    <tr>
                                        <td colSpan='5' className="text-center">
                                            <Spinner animation="bCustomer" variant="info"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                ) : (
                                    <tbody>
                                    {
                                        recordList.map((e, index) => (
                                            <tr key={index}>
                                                <td>{rowNo++}</td>
                                                <td style={{width: "10%"}}>{e.name}</td>
                                                <td style={{width: "10%"}}>{e.last_name}</td>
                                                <td style={{width: "10%"}}>{e.country}</td>
                                                <td style={{width: "10%"}}>{e.email}</td>
                                                <td style={{width: "10%"}}>{e.phone}</td>
                                                <td style={{width: "10%"}}>{e.address}</td>
                                                {/*<td>*/}
                                                {/*    <Dropdown>*/}
                                                {/*        <Dropdown.Toggle variant="outline-info" id="dropdown-basic">*/}
                                                {/*            <span className="nc-icon nc-bullet-list-67"/>*/}
                                                {/*        </Dropdown.Toggle>*/}
                                                {/*        <Dropdown.Menu>*/}
                                                {/*            <Button title="View Detail" style={{whiteSpace: "nowrap"}}*/}
                                                {/*                    className="btn-round" variant="light" type="Button"*/}
                                                {/*                    onClick={async () => {*/}
                                                {/*                        await props.setShowModal(true);*/}
                                                {/*                    }}>*/}
                                                {/*                <span*/}
                                                {/*                    className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;*/}
                                                {/*                View Detail*/}
                                                {/*            </Button>*/}
                                                {/*        </Dropdown.Menu>*/}
                                                {/*    </Dropdown>*/}
                                                {/*</td>*/}
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                )
                                }
                            </Table>
                            <div className="page-content-center">
                                {
                                    loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="bCustomer" variant="info"/>
                                            </div>
                                        ) :
                                        (
                                            props.list.last_page >= pageNo ?
                                                <button className="btn  bg-light text-info" title="Load More"
                                                        onClick={(pageNumber => {
                                                            nextPageLoadList(pageNumber);
                                                        })}>
                                                    <span className="nc-icon nc-simple-add text-info"/>
                                                </button>
                                                : ""
                                        )
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListCustomer;
