import React, {useEffect, useState} from "react";
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {Spinner} from "react-bootstrap"
import UNICEF from "./../assets/img/unhcr.jpg";
import WADAN from "./../assets/img/wadan.png";
import logoMAF from "./../assets/img/logoMAF.png";
import {useTranslation} from 'react-i18next';

import Axios from "axios";
import {mainUrl} from "common/common.js";
import PieChart from "./chart/PieChart";
import PieChartCustomerStatus from "./chart/PieChartCustomerStatus";
import PieChartOrderStatus from "./chart/PieChartOrderStatus";

function Dashboard() {
    const {t, i18n} = useTranslation();


    const [isLoading, setIsLoading] = useState(true);
    const [totalUser, setTotalUser] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalCustomer, setTotalCustomer] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const [totalNewOrder, setTotalNewOrder] = useState(0);
    const [property, setProperty] = useState([]);
    const [title, setTitle] = useState("");
    const [dataChart, setDataChart] = useState([122, 12]);
    const [colorChart, setColorChart] = useState(["#ffc107", "#007bff"]);


    const [colorChartCustomer, setColorChartCustomer] = useState(["#ff074d", "#f3f5f6",]);
    const [dataChartCustomer, setDataChartCustomer] = useState([122,12]);
    const [propertyCustomer, setPropertyCustomer] = useState([]);
    const [titleCustomer, setTitleCustomer] = useState("");
    const [totalUsersCustomer, setTotalUsersCustomer] = useState(0);


    const [colorChartOrder, setColorChartOrder] = useState(["#4bf584", "#004e72","#f5b107", "#ddf2fa"]);
    const [dataChartOrder, setDataChartOrder] = useState([122,12]);
    const [propertyOrder, setPropertyOrder] = useState([]);
    const [titleOrder, setTitleOrder] = useState("");
    const [totalUsersOrder, setTotalUsersOrder] = useState(0);

    useEffect(() => {
        dashboardDetail();

        productAnalisize();
        customerAnalisize();
        orderAnalisize();
    }, []);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const dashboardDetail = async() => {
        await Axios.get(mainUrl + "dashboardStatus", {headers}).then(res => {

            setTotalNewOrder(res.data.totalNewOrder);
            setTotalOrder(res.data.totalOrder);
            setTotalCustomer(res.data.totalCustomer);
            setTotalUser(res.data.totalUser);
        });
    }

    const colorGenerator = (n) => {
        var color = [];
        for (let v = 0; v < n; v++) {
            var rgb = [];
            for (var i = 1; i < 4; i++)
                rgb.push(Math.floor(Math.random() * 255));
            color[v] = 'rgb(' + rgb.join(',') + ')';
        }
        return color;
    }
    const customerAnalisize = async () =>   {
        await Axios.get(mainUrl + "CustomerStatus", {headers}).then(res => {
            const value = [];
            const prop = [];
            let count = 0;
            res.data.map((e, index) => {
                count = count + e.total;
                value[index] = e.counts;
                prop[index] = e.name;
            });
            var color = colorGenerator(value.length);
            let pr = [];
            for (let v = 0; v < value.length; v++) {
                pr[v] = <div> {color[v]} {prop[v]} {value[v]}</div>
            }
            setColorChartCustomer(color);
            setTotalUsersCustomer(count);
            // setTotalNewOrderCustomer(value.length);
            setDataChartCustomer(value);
            setPropertyCustomer(pr);
            setTitleCustomer("Customer Status ");
        });
    }
    const orderAnalisize = async () => {
        await Axios.get(mainUrl + "OrderStatus", {headers}).then(res => {
            const value = [];
            const prop = [];
            let count = 0;
            res.data.map((e, index) => {
                if(e.name!=55) {
                    count = count + e.total;
                    value[index] = e.counts;
                    if(e.name==1) {prop[index] = 'Temporary Request';}
                    else if(e.name==2) {prop[index] = 'New Request';}
                    else if(e.name==3) {prop[index] = 'In Process';}
                    else if(e.name==4) {prop[index] = 'Completed';}
                }
            });
            var color = colorGenerator(value.length);
            let pr = [];
            for (let v = 0; v < value.length; v++) {
                pr[v] = <div> {color[v]} {prop[v]} {value[v]}</div>
            }

            setColorChartOrder(color);
            setTotalUsersOrder(count);
            // setTotalNewOrderOrder(value.length);
            setDataChartOrder(value);
            setPropertyOrder(pr);
            setTitleOrder("Request Status ");
        });
    }
    const productAnalisize = async () => {
        await Axios.get(mainUrl + "productCategoryStatus", {headers}).then(res => {
            const value = [];
            const prop = [];
            let count = 0;
            res.data.map((e, index) => {
                count = count + e.total;
                value[index] = e.counts;
                prop[index] = e.name;
            });
            var color = colorGenerator(value.length);
            let pr = [];
            for (let v = 0; v < value.length; v++) {
                pr[v] = <div>  {color[v]} {prop[v]} {value[v]}</div>
            }

            setColorChart(color);
            setTotalUsers(count);
            // setTotalNewOrderOrder(value.length);
            setDataChart(value);
            setProperty(pr);
            setTitle("Product Status ");
        });
    }

    return (
        <>
            <div className="content">
                {isLoading === false ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="info"/>
                    </div>
                ) : (
                    <div>
                        <Row>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-user-run text-warning"/>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total User
                                                        {/*{t('totalAdminUser')}*/}
                                                    </p>
                                                    <CardTitle tag="p">{totalUser}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        {/*<button className="btn btn-outline-warning"*/}
                                        {/*        style={{width: '100%', fontSize: '20px'}}*/}
                                        {/*>*/}
                                        {/*    User Status*/}
                                        {/*</button>*/}
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-app text-info"></i>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Total Customer</p>
                                                    <CardTitle tag="p">{totalCustomer}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        {/*<button className="btn btn-outline-info"*/}
                                        {/*        style={{width: '100%', fontSize: '20px'}}>*/}
                                        {/*    Customer Status*/}
                                        {/*</button>*/}
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-money-coins text-success"/>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Total Order</p>
                                                    <CardTitle tag="p">{totalOrder}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        {/*<button className="btn btn-outline-success "*/}
                                        {/*        style={{width: '100%', fontSize: '20px'}}>*/}
                                        {/*    Total Order Status*/}
                                        {/*</button>*/}
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-vector text-danger"/>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Total New Order</p>
                                                    <CardTitle tag="p">{totalNewOrder}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        {/*<button className="btn btn-outline-danger"*/}
                                        {/*        style={{width: '100%', fontSize: '20px'}}>*/}
                                        {/*    New Order Status*/}
                                        {/*</button>*/}
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{display: 'none',}}>
                            {/*chart start*/}
                            {/*<PieChart colorChart={colorChart} dataChart={dataChart} property={property} title={title} totalUsers={totalUsers}/>*/}

                            {/*chart end*/}
                            <Col>
                                <Card style={{height:'800px',minWidth:'250px'}}>
                                    <CardHeader>
                                        <CardTitle tag="h5">WADAN</CardTitle>
                                        <p className="card-category">
                                            Established in August 2002, WADAN is an indigenous, government registered
                                            NGO.
                                        </p>
                                    </CardHeader>
                                    <CardBody style={{height: "166px"}} className="text-center">
                                        <img src={WADAN} alt="Aria Delta "/>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        <div className="stats" style={{textAlign: "justify"}}>
                                            <i className="fa fa-calendar"/>
                                            WADAN envisions a peaceful, drug free, democratic and developed Afghanistan.
                                            Their mission is to advance the spread of democratic principles, sustainable
                                            development, social justice, human rights, and freedom in Afghanistan.
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col>
                                <Card style={{height:'800px',minWidth:'250px'}}>
                                    <CardHeader>
                                        <CardTitle tag="h5">UNHCR</CardTitle>
                                        <p className="card-category">United Nations High Commissioner for Refugees .</p>
                                    </CardHeader>
                                    <CardBody style={{height: "400px"}}>
                                        <img src={UNICEF} alt="Unicef "/>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        <div className="stats" style={{textAlign: "justify"}}>
                                            <i className="fa fa-calendar"/> The United Nations High Commissioner for Refugees is a United Nations agency mandated to aid and protect refugees, forcibly displaced communities, and stateless people, and to assist in their voluntary repatriation, local integration or resettlement to a third country.

                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col>
                                <Card style={{height:'800px',minWidth:'400px'}}>
                                    <CardHeader>
                                        <CardTitle tag="h5">Made in Afghanistan</CardTitle>
                                        <p className="card-category"></p>
                                    </CardHeader>
                                    <CardBody style={{height: "300px", textAlign: "center"}}>
                                        <img src={logoMAF} alt="Made in Afgghanistan "/>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        <div className="stats" style={{textAlign: "justify"}}>
                                            <i className="fa fa-calendar"/> For over 2 decades there has been a high
                                            influx of refugee returnees, and IDPs due to the ongoing conflict, increased
                                            violence, and natural disasters in Afghanistan. The number of returnees to
                                            Afghanistan has increased in the past 10 years. Returnees and displaced
                                            populations try to find habitat in different provinces, including Herat,
                                            Kandahar, Nangarhar, and Balkh where the host communities are already under
                                            pressure to find economic opportunities. Herat is among those provinces
                                            overwhelmed with the arrival of returnees and IDPs. The returnees and IDPs
                                            are in severe economic and emotional conditions which have an adverse impact
                                            on their livelihood. In this project, 50 artisans from refugee returnees,
                                            IDPs, and Host Communities under UNHCR’s support have working in the
                                            Artisanal Business Center (ABC) for design, production, domestic, and
                                            international market linkages to improve the livelihood of persons of
                                            concern (PoC). MaA was redesigned in late June 2020 aimed at mobilizing
                                            these artisans under a domestic LSE and transition them to Self-Help Group
                                            (SGH). This project would improve the capacity of SGH to ensure they become
                                            self-reliant and self-sufficient.

                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>

                        </Row>
                    </div>
                )}
                <Row>
                    <Col md={4}>
                        <PieChart colorChart={colorChart} dataChart={dataChart} property={property} title={title} totalUsers={totalUsers}/>
                    </Col>

                    <Col md={4}>
                        <PieChartCustomerStatus colorChart={colorChartCustomer} dataChart={dataChartCustomer} property={propertyCustomer} title={titleCustomer} totalUsers={totalUsersCustomer}/>
                    </Col>

                    <Col md={4}>
                        <PieChartOrderStatus colorChart={colorChartOrder} dataChart={dataChartOrder} property={propertyOrder} title={titleOrder} totalUsers={totalUsersOrder}/>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Dashboard;
