import Axios from "axios";
import {mainUrl} from "../../common/common";
import {CardBody} from "reactstrap";
import {Button, Col, FormGroup, Row} from "react-bootstrap";
import React, {useState} from "react";

// import {useEffect} from "preact";

function SearchStory(props) {
    const [selectedOption, setSelectedOption] = useState(null);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const searchStory = async (e) => {
        e.preventDefault();
        const f = e.target;
        props.setSearchParameter({
            'title': f.sh_title.value
        });
        props.setSearchStatus(true);
        const {data} = await Axios.post(mainUrl + "searchStory?page=1",
            {
                'title': f.sh_title.value
            }, {headers});
        const lists = data;
        props.setList(lists);
    };

    return (
        <>
            <CardBody className="mr-4 ml-4">
                <form onSubmit={(e) => searchStory(e)}>
                    <Row>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label> Title <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Story Title" name="sh_title" id="sh_title"
                                       placeholder="Story Title" type="text"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <div className="ml-end">
                            <Button title="Search" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Search
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </form>
            </CardBody>
        </>
    );
}


export default SearchStory;
