
const englishWords= {
    "totalAdminUser": "Total User",
    "systemName": "Made in Afghanistan Management System",
    "dashboard": "Dashboard",
    "userProfile": "User Product",
    "setting": "Setting",
    "report": "Report"
}


export default englishWords;
