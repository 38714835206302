export const countryList = [
    {
        code: null,
        value: null
        , label: ".`. ."
    },{
        code: "AF",
        value: "Afghanistan"
        , label: "Afghanistan"
    },
    {
        code: "AB",
        value: "Abkhazia"
        , label: "Abkhazia"
    },
    {
        code: "AC",
        value: "Ascension Island"
        , label: "Ascension Island"
    },
    {
        code: "AD",
        value: "Andorra"
        , label: "Andorra"
    },
    {
        code: "AE",
        value: "United Arab Emirates"
        , label: "United Arab Emirates"
    },
    {
        code: "AG",
        value: "Antigua and Barbuda"
        , label: "Antigua and Barbuda"
    },
    {
        code: "AI",
        value: "Anguilla"
        , label: "Anguilla"
    },
    {
        code: "AL",
        value: "Albania"
        , label: "Albania"
    },
    {
        code: "AM",
        value: "Armenia"
        , label: "Armenia"
    },
    {
        code: "AO",
        value: "Angola"
        , label: "Angola"
    },
    {
        code: "AQ",
        value: "Antarctica"
        , label: "Antarctica"
    },
    {
        code: "AR",
        value: "Argentina"
        , label: "Argentina"
    },
    {
        code: "AS",
        value: "American Samoa"
        , label: "American Samoa"
    },
    {
        code: "AT",
        value: "Austria"
        , label: "Austria"
    },
    {
        code: "AU",
        value: "Australia"
        , label: "Australia"
    },
    {
        code: "AW",
        value: "Aruba"
        , label: "Aruba"
    },
    {
        code: "AX",
        value: "Åland Islands"
        , label: "Åland Islands"
    },
    {
        code: "AZ",
        value: "Azerbaijan"
        , label: "Azerbaijan"
    },
    {
        code: "BA",
        value: "Bosnia and Herzegovina"
        , label: "Bosnia and Herzegovina"
    },
    {
        code: "BB",
        value: "Barbados"
        , label: "Barbados"
    },
    {
        code: "BD",
        value: "Bangladesh"
        , label: "Bangladesh"
    },
    {
        code: "BE",
        value: "Belgium"
        , label: "Belgium"
    },
    {
        code: "BF",
        value: "Burkina Faso"
        , label: "Burkina Faso"
    },
    {
        code: "BG",
        value: "Bulgaria"
        , label: "Bulgaria"
    },
    {
        code: "BH",
        value: "Bahrain"
        , label: "Bahrain"
    },
    {
        code: "BI",
        value: "Burundi"
        , label: "Burundi"
    },
    {
        code: "BJ",
        value: "Benin"
        , label: "Benin"
    },
    {
        code: "BL",
        value: "Saint Barthélemy"
        , label: "Saint Barthélemy"
    },
    {
        code: "BM",
        value: "Bermuda"
        , label: "Bermuda"
    },
    {
        code: "BN",
        value: "Brunei Darussalam"
        , label: "Brunei Darussalam"
    },
    {
        code: "BO",
        value: "Bolivia"
        , label: "Bolivia"
    },
    {
        code: "BQ",
        value: "Bonaire}, Sint Eustatius and Saba"
        , label: "Bonaire}, Sint Eustatius and Saba"
    },
    {
        code: "BR",
        value: "Brazil"
        , label: "Brazil"
    },
    {
        code: "BS",
        value: "Bahamas"
        , label: "Bahamas"
    },
    {
        code: "BT",
        value: "Bhutan"
        , label: "Bhutan"
    },
    {
        code: "BV",
        value: "Bouvet Island"
        , label: "Bouvet Island"
    },
    {
        code: "BW",
        value: "Botswana"
        , label: "Botswana"
    },
    {
        code: "BY",
        value: "Belarus"
        , label: "Belarus"
    },
    {
        code: "BZ",
        value: "Belize"
        , label: "Belize"
    },
    {
        code: "CA",
        value: "Canada"
        , label: "Canada"
    },
    {
        code: "CC",
        value: "Cocos (Keeling) Islands"
        , label: "Cocos (Keeling) Islands"
    },
    {
        code: "CD",
        value: "Congo}, Democratic Republic of the"
        , label: "Congo}, Democratic Republic of the"
    },
    {
        code: "CF",
        value: "Central African Republic"
        , label: "Central African Republic"
    },
    {
        code: "CG",
        value: "Congo"
        , label: "Congo"
    },
    {
        code: "CH",
        value: "Switzerland"
        , label: "Switzerland"
    },
    {
        code: "CI",
        value: "Cote d'Ivoire"
        , label: "Cote d'Ivoire"
    },
    {
        code: "CK",
        value: "Cook Islands"
        , label: "Cook Islands"
    },
    {
        code: "CL",
        value: "Chile"
        , label: "Chile"
    },
    {
        code: "CM",
        value: "Cameroon"
        , label: "Cameroon"
    },
    {
        code: "CN",
        value: "China"
        , label: "China"
    },
    {
        code: "CO",
        value: "Colombia"
        , label: "Colombia"
    },
    {
        code: "CR",
        value: "Costa Rica"
        , label: "Costa Rica"
    },
    {
        code: "CU",
        value: "Cuba"
        , label: "Cuba"
    },
    {
        code: "CV",
        value: "Cape Verde"
        , label: "Cape Verde"
    },
    {
        code: "CW",
        value: "Curaçao"
        , label: "Curaçao"
    },
    {
        code: "CX",
        value: "Christmas Island"
        , label: "Christmas Island"
    },
    {
        code: "CY",
        value: "Cyprus"
        , label: "Cyprus"
    },
    {
        code: "CZ",
        value: "Czech Republic"
        , label: "Czech Republic"
    },
    {
        code: "DE",
        value: "Germany"
        , label: "Germany"
    },
    {
        code: "DJ",
        value: "Djibouti"
        , label: "Djibouti"
    },
    {
        code: "DK",
        value: "Denmark"
        , label: "Denmark"
    },
    {
        code: "DM",
        value: "Dominica"
        , label: "Dominica"
    },
    {
        code: "DO",
        value: "Dominican Republic"
        , label: "Dominican Republic"
    },
    {
        code: "DZ",
        value: "Algeria"
        , label: "Algeria"
    },
    {
        code: "EC",
        value: "Ecuador"
        , label: "Ecuador"
    },
    {
        code: "EE",
        value: "Estonia"
        , label: "Estonia"
    },
    {
        code: "EG",
        value: "Egypt"
        , label: "Egypt"
    },
    {
        code: "EH",
        value: "Western Sahara"
        , label: "Western Sahara"
    },
    {
        code: "ER",
        value: "Eritrea"
        , label: "Eritrea"
    },
    {
        code: "ES",
        value: "Spain"
        , label: "Spain"
    },
    {
        code: "ET",
        value: "Ethiopia"
        , label: "Ethiopia"
    },
    {
        code: "FI",
        value: "Finland"
        , label: "Finland"
    },
    {
        code: "FJ",
        value: "Fiji"
        , label: "Fiji"
    },
    {
        code: "FK",
        value: "Falkland Islands"
        , label: "Falkland Islands"
    },
    {
        code: "FM",
        value: "Federated States of Micronesia"
        , label: "Federated States of Micronesia"
    },
    {
        code: "FO",
        value: "Faroe Islands"
        , label: "Faroe Islands"
    },
    {
        code: "FR",
        value: "France"
        , label: "France"
    },
    {
        code: "GA",
        value: "Gabon"
        , label: "Gabon"
    },
    {
        code: "GB",
        value: "United Kingdom"
        , label: "United Kingdom"
    },
    {
        code: "GD",
        value: "Grenada"
        , label: "Grenada"
    },
    {
        code: "GE",
        value: "Georgia"
        , label: "Georgia"
    },
    {
        code: "GF",
        value: "French Guiana"
        , label: "French Guiana"
    },
    {
        code: "GG",
        value: "Guernsey"
        , label: "Guernsey"
    },
    {
        code: "GH",
        value: "Ghana"
        , label: "Ghana"
    },
    {
        code: "GI",
        value: "Gibraltar"
        , label: "Gibraltar"
    },
    {
        code: "GL",
        value: "Greenland"
        , label: "Greenland"
    },
    {
        code: "GM",
        value: "Gambia"
        , label: "Gambia"
    },
    {
        code: "GN",
        value: "Guinea"
        , label: "Guinea"
    },
    {
        code: "GP",
        value: "Guadeloupe"
        , label: "Guadeloupe"
    },
    {
        code: "GQ",
        value: "Equatorial Guinea"
        , label: "Equatorial Guinea"
    },
    {
        code: "GR",
        value: "Greece"
        , label: "Greece"
    },
    {
        code: "GS",
        value: "South Georgia and the South Sandwich Islands"
        , label: "South Georgia and the South Sandwich Islands"
    },
    {
        code: "GT",
        value: "Guatemala"
        , label: "Guatemala"
    },
    {
        code: "GU",
        value: "Guam"
        , label: "Guam"
    },
    {
        code: "GW",
        value: "Guinea-Bissau"
        , label: "Guinea-Bissau"
    },
    {
        code: "GY",
        value: "Guyana"
        , label: "Guyana"
    },
    {
        code: "HK",
        value: "Hong Kong"
        , label: "Hong Kong"
    },
    {
        code: "HM",
        value: "Heard Island and McDonald Islands"
        , label: "Heard Island and McDonald Islands"
    },
    {
        code: "HN",
        value: "Honduras"
        , label: "Honduras"
    },
    {
        code: "HR",
        value: "Croatia"
        , label: "Croatia"
    },
    {
        code: "HT",
        value: "Haiti"
        , label: "Haiti"
    },
    {
        code: "HU",
        value: "Hungary"
        , label: "Hungary"
    },
    {
        code: "ID",
        value: "Indonesia"
        , label: "Indonesia"
    },
    {
        code: "IE",
        value: "Ireland"
        , label: "Ireland"
    },
    {
        code: "IL",
        value: "Israel"
        , label: "Israel"
    },
    {
        code: "IM",
        value: "Isle of Man"
        , label: "Isle of Man"
    },
    {
        code: "IN",
        value: "India"
        , label: "India"
    },
    {
        code: "IO",
        value: "British Indian Ocean Territory"
        , label: "British Indian Ocean Territory"
    },
    {
        code: "IQ",
        value: "Iraq"
        , label: "Iraq"
    },
    {
        code: "IR",
        value: "Iran"
        , label: "Iran"
    },
    {
        code: "IS",
        value: "Iceland"
        , label: "Iceland"
    },
    {
        code: "IT",
        value: "Italy"
        , label: "Italy"
    },
    {
        code: "JE",
        value: "Jersey"
        , label: "Jersey"
    },
    {
        code: "JM",
        value: "Jamaica"
        , label: "Jamaica"
    },
    {
        code: "JO",
        value: "Jordan"
        , label: "Jordan"
    },
    {
        code: "JP",
        value: "Japan"
        , label: "Japan"
    },
    {
        code: "KE",
        value: "Kenya"
        , label: "Kenya"
    },
    {
        code: "KG",
        value: "Kyrgyzstan"
        , label: "Kyrgyzstan"
    },
    {
        code: "KH",
        value: "Cambodia"
        , label: "Cambodia"
    },
    {
        code: "KI",
        value: "Kiribati"
        , label: "Kiribati"
    },
    {
        code: "KM",
        value: "Comoros"
        , label: "Comoros"
    },
    {
        code: "KN",
        value: "Saint Kitts and Nevis"
        , label: "Saint Kitts and Nevis"
    },
    {
        code: "KP",
        value: "North Korea"
        , label: "North Korea"
    },
    {
        code: "KR",
        value: "South Korea"
        , label: "South Korea"
    },
    {
        code: "KW",
        value: "Kuwait"
        , label: "Kuwait"
    },
    {
        code: "KY",
        value: "Cayman Islands"
        , label: "Cayman Islands"
    },
    {
        code: "KZ",
        value: "Kazakhstan"
        , label: "Kazakhstan"
    },
    {
        code: "LA",
        value: "Laos"
        , label: "Laos"
    },
    {
        code: "LB",
        value: "Lebanon"
        , label: "Lebanon"
    },
    {
        code: "LC",
        value: "Saint Lucia"
        , label: "Saint Lucia"
    },
    {
        code: "LI",
        value: "Liechtenstein"
        , label: "Liechtenstein"
    },
    {
        code: "LK",
        value: "Sri Lanka"
        , label: "Sri Lanka"
    },
    {
        code: "LR",
        value: "Liberia"
        , label: "Liberia"
    },
    {
        code: "LS",
        value: "Lesotho"
        , label: "Lesotho"
    },
    {
        code: "LT",
        value: "Lithuania"
        , label: "Lithuania"
    },
    {
        code: "LU",
        value: "Luxembourg"
        , label: "Luxembourg"
    },
    {
        code: "LV",
        value: "Latvia"
        , label: "Latvia"
    },
    {
        code: "LY",
        value: "Libya"
        , label: "Libya"
    },
    {
        code: "MA",
        value: "Morocco"
        , label: "Morocco"
    },
    {
        code: "MC",
        value: "Monaco"
        , label: "Monaco"
    },
    {
        code: "MD",
        value: "Moldova"
        , label: "Moldova"
    },
    {
        code: "ME",
        value: "Montenegro"
        , label: "Montenegro"
    },
    {
        code: "MF",
        value: "Saint Martin (French Part)"
        , label: "Saint Martin (French Part)"
    },
    {
        code: "MG",
        value: "Madagascar"
        , label: "Madagascar"
    },
    {
        code: "MH",
        value: "Marshall Islands"
        , label: "Marshall Islands"
    },
    {
        code: "MK",
        value: "North Macedonia"
        , label: "North Macedonia"
    },
    {
        code: "ML",
        value: "Mali"
        , label: "Mali"
    },
    {
        code: "MM",
        value: "Myanmar"
        , label: "Myanmar"
    },
    {
        code: "MN",
        value: "Mongolia"
        , label: "Mongolia"
    },
    {
        code: "MO",
        value: "Macao"
        , label: "Macao"
    },
    {
        code: "MP",
        value: "Northern Mariana Islands"
        , label: "Northern Mariana Islands"
    },
    {
        code: "MQ",
        value: "Martinique"
        , label: "Martinique"
    },
    {
        code: "MR",
        value: "Mauritania"
        , label: "Mauritania"
    },
    {
        code: "MS",
        value: "Montserrat"
        , label: "Montserrat"
    },
    {
        code: "MT",
        value: "Malta"
        , label: "Malta"
    },
    {
        code: "MU",
        value: "Mauritius"
        , label: "Mauritius"
    },
    {
        code: "MV",
        value: "Maldives"
        , label: "Maldives"
    },
    {
        code: "MW",
        value: "Malawi"
        , label: "Malawi"
    },
    {
        code: "MX",
        value: "Mexico"
        , label: "Mexico"
    },
    {
        code: "MY",
        value: "Malaysia"
        , label: "Malaysia"
    },
    {
        code: "MZ",
        value: "Mozambique"
        , label: "Mozambique"
    },
    {
        code: "NA",
        value: "Namibia"
        , label: "Namibia"
    },
    {
        code: "NC",
        value: "New Caledonia"
        , label: "New Caledonia"
    },
    {
        code: "NE",
        value: "Niger"
        , label: "Niger"
    },
    {
        code: "NF",
        value: "Norfolk Island"
        , label: "Norfolk Island"
    },
    {
        code: "NG",
        value: "Nigeria"
        , label: "Nigeria"
    },
    {
        code: "NI",
        value: "Nicaragua"
        , label: "Nicaragua"
    },
    {
        code: "NL",
        value: "Netherlands"
        , label: "Netherlands"
    },
    {
        code: "NO",
        value: "Norway"
        , label: "Norway"
    },
    {
        code: "NP",
        value: "Nepal"
        , label: "Nepal"
    },
    {
        code: "NR",
        value: "Nauru"
        , label: "Nauru"
    },
    {
        code: "NU",
        value: "Niue"
        , label: "Niue"
    },
    {
        code: "NZ",
        value: "New Zealand"
        , label: "New Zealand"
    },
    {
        code: "OM",
        value: "Oman"
        , label: "Oman"
    },
    {
        code: "OS",
        value: "South Ossetia"
        , label: "South Ossetia"
    },
    {
        code: "PA",
        value: "Panama"
        , label: "Panama"
    },
    {
        code: "PE",
        value: "Peru"
        , label: "Peru"
    },
    {
        code: "PF",
        value: "French Polynesia"
        , label: "French Polynesia"
    },
    {
        code: "PG",
        value: "Papua New Guinea"
        , label: "Papua New Guinea"
    },
    {
        code: "PH",
        value: "Philippines"
        , label: "Philippines"
    },
    {
        code: "PK",
        value: "Pakistan"
        , label: "Pakistan"
    },
    {
        code: "PL",
        value: "Poland"
        , label: "Poland"
    },
    {
        code: "PM",
        value: "Saint Pierre and Miquelon"
        , label: "Saint Pierre and Miquelon"
    },
    {
        code: "PN",
        value: "Pitcairn"
        , label: "Pitcairn"
    },
    {
        code: "PR",
        value: "Puerto Rico"
        , label: "Puerto Rico"
    },
    {
        code: "PS",
        value: "Palestine"
        , label: "Palestine"
    },
    {
        code: "PT",
        value: "Portugal"
        , label: "Portugal"
    },
    {
        code: "PW",
        value: "Palau"
        , label: "Palau"
    },
    {
        code: "PY",
        value: "Paraguay"
        , label: "Paraguay"
    },
    {
        code: "QA",
        value: "Qatar"
        , label: "Qatar"
    },
    {
        code: "RE",
        value: "Reunion"
        , label: "Reunion"
    },
    {
        code: "RO",
        value: "Romania"
        , label: "Romania"
    },
    {
        code: "RS",
        value: "Serbia"
        , label: "Serbia"
    },
    {
        code: "RU",
        value: "Russia"
        , label: "Russia"
    },
    {
        code: "RW",
        value: "Rwanda"
        , label: "Rwanda"
    },
    {
        code: "SA",
        value: "Saudi Arabia"
        , label: "Saudi Arabia"
    },
    {
        code: "SB",
        value: "Solomon Islands"
        , label: "Solomon Islands"
    },
    {
        code: "SC",
        value: "Seychelles"
        , label: "Seychelles"
    },
    {
        code: "SD",
        value: "Sudan"
        , label: "Sudan"
    },
    {
        code: "SE",
        value: "Sweden"
        , label: "Sweden"
    },
    {
        code: "SG",
        value: "Singapore"
        , label: "Singapore"
    },
    {
        code: "SH",
        value: "Saint Helena"
        , label: "Saint Helena"
    },
    {
        code: "SI",
        value: "Slovenia"
        , label: "Slovenia"
    },
    {
        code: "SJ",
        value: "Svalbard and Jan Mayen"
        , label: "Svalbard and Jan Mayen"
    },
    {
        code: "SK",
        value: "Slovakia"
        , label: "Slovakia"
    },
    {
        code: "SL",
        value: "Sierra Leone"
        , label: "Sierra Leone"
    },
    {
        code: "SM",
        value: "San Marino"
        , label: "San Marino"
    },
    {
        code: "SN",
        value: "Senegal"
        , label: "Senegal"
    },
    {
        code: "SO",
        value: "Somalia"
        , label: "Somalia"
    },
    {
        code: "SR",
        value: "Surivalue"
        , label: "Surivalue"
    },
    {
        code: "SS",
        value: "South Sudan"
        , label: "South Sudan"
    },
    {
        code: "ST",
        value: "Sao Tome and Principe"
        , label: "Sao Tome and Principe"
    },
    {
        code: "SV",
        value: "El Salvador"
        , label: "El Salvador"
    },
    {
        code: "SX",
        value: "Sint Maarten"
        , label: "Sint Maarten"
    },
    {
        code: "SY",
        value: "Syria"
        , label: "Syria"
    },
    {
        code: "SZ",
        value: "Swaziland"
        , label: "Swaziland"
    },
    {
        code: "TA",
        value: "Tristan da Cunha"
        , label: "Tristan da Cunha"
    },
    {
        code: "TC",
        value: "Turks and Caicos Islands"
        , label: "Turks and Caicos Islands"
    },
    {
        code: "TD",
        value: "Chad"
        , label: "Chad"
    },
    {
        code: "TF",
        value: "French Southern Territories"
        , label: "French Southern Territories"
    },
    {
        code: "TG",
        value: "Togo"
        , label: "Togo"
    },
    {
        code: "TH",
        value: "Thailand"
        , label: "Thailand"
    },
    {
        code: "TJ",
        value: "Tajikistan"
        , label: "Tajikistan"
    },
    {
        code: "TK",
        value: "Tokelau"
        , label: "Tokelau"
    },
    {
        code: "TL",
        value: "Timor-Leste"
        , label: "Timor-Leste"
    },
    {
        code: "TM",
        value: "Turkmenistan"
        , label: "Turkmenistan"
    },
    {
        code: "TN",
        value: "Tunisia"
        , label: "Tunisia"
    },
    {
        code: "TO",
        value: "Tonga"
        , label: "Tonga"
    },
    {
        code: "TR",
        value: "Turkey"
        , label: "Turkey"
    },
    {
        code: "TT",
        value: "Trinidad and Tobago"
        , label: "Trinidad and Tobago"
    },
    {
        code: "TV",
        value: "Tuvalu"
        , label: "Tuvalu"
    },
    {
        code: "TW",
        value: "Taiwan"
        , label: "Taiwan"
    },
    {
        code: "TZ",
        value: "Tanzania"
        , label: "Tanzania"
    },
    {
        code: "UA",
        value: "Ukraine"
        , label: "Ukraine"
    },
    {
        code: "UG",
        value: "Uganda"
        , label: "Uganda"
    },
    {
        code: "UM",
        value: "United States Minor Outlying Islands"
        , label: "United States Minor Outlying Islands"
    },
    {
        code: "US",
        value: "United States"
        , label: "United States"
    },
    {
        code: "UY",
        value: "Uruguay"
        , label: "Uruguay"
    },
    {
        code: "UZ",
        value: "Uzbekistan"
        , label: "Uzbekistan"
    },
    {
        code: "VA",
        value: "Holy See (Vatican City State)"
        , label: "Holy See (Vatican City State)"
    },
    {
        code: "VC",
        value: "Saint Vincent and the Grenadines"
        , label: "Saint Vincent and the Grenadines"
    },
    {
        code: "VE",
        value: "Venezuela"
        , label: "Venezuela"
    },
    {
        code: "VG",
        value: "Virgin Islands}, British"
        , label: "Virgin Islands}, British"
    },
    {
        code: "VI",
        value: "Virgin Islands}, U.S."
        , label: "Virgin Islands}, U.S."
    },
    {
        code: "VN",
        value: "Vietnam"
        , label: "Vietnam"
    },
    {
        code: "VU",
        value: "Vanuatu"
        , label: "Vanuatu"
    },
    {
        code: "WF",
        value: "Wallis and Futuna"
        , label: "Wallis and Futuna"
    },
    {
        code: "WS",
        value: "Samoa"
        , label: "Samoa"
    },
    {
        code: "XK",
        value: "Kosovo"
        , label: "Kosovo"
    },
    {
        code: "YE",
        value: "Yemen"
        , label: "Yemen"
    },
    {
        code: "YT",
        value: "Mayotte"
        , label: "Mayotte"
    },
    {
        code: "ZA",
        value: "South Africa"
        , label: "South Africa"
    },
    {
        code: "ZM",
        value: "Zambia"
        , label: "Zambia"
    },
    {
        code: "ZW",
        value: "Zimbabw" ,
        label: "Zimbabw" ,
    }
];
