import Axios from "axios";
import {mainUrl} from "../../common/common";
import {CardBody, Input} from "reactstrap";
import {Button, Col, FormGroup, Row} from "react-bootstrap";
import React from "react";


function SearchProduct(props) {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const searchProduct = async (e) => {
        e.preventDefault();
        const f = e.target;
        props.setSearchParameter({
            'category_id':f.category_ids.value,
            'sub_category_id':f.sub_category_ids.value,
            'product_name':f.product_names.value
        });
        props.setSearchStatus(true);
        const {data} = await Axios.post(mainUrl + "searchProduct?page=1",
            {
                'category_id':f.category_ids.value,
                'sub_category_id':f.sub_category_ids.value,
                'product_name':f.product_names.value
            }, {headers});
        const lists = data;
        props.setList(lists);
    };

    return (
        <>
            <CardBody className="mr-4 ml-4">
                <form onSubmit={(e) => searchProduct(e)}>
                    <Row>

                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>Category <span className="text text-danger">*</span></label>
                                <select title="Select Project" name="category_ids" id='category_ids' className="form-control">
                                    <option value=""></option>
                                    {
                                        props.categoryList.map((e, index) => (
                                            <option key={index} value={e.id}>{e.name}</option>))
                                    }
                                </select>
                            </FormGroup>
                        </Col>

                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>Sub Category <span className="text text-danger">*</span></label>
                                <select title="Select Province" name="sub_category_ids" id='sub_category_ids' className="form-control">
                                    <option value=""></option>
                                    {
                                        props.subCategoryList.map((e, index) => (
                                            <option key={index} value={e.id}>{e.name}</option>))
                                    }
                                </select>
                            </FormGroup>
                        </Col>

                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>Product <span className="text text-danger">*</span></label>
                                <Input placeholder="Product Name" name="product_names" id="product_names" className="form-control"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <div className="ml-end">
                            <Button title="Search" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Search
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </form>
            </CardBody>
        </>
    );
}


export default SearchProduct;
