import React, {useEffect, useState} from "react";
import ImageSelect from "./utils/imageSelect";
import {Button, Col, FormGroup, Modal, Row} from "react-bootstrap";
import image from "../../assets/img/image.png";
import {Input} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common.js";
import Select from "react-select";
import {UserTypeList} from "../../common/UserTypeList";

function AddProfile(props) {

    const [showUploadImage, setShowUploadImage] = useState(false);
    const [downloadStatus, setDownloadStatus] = useState(false);
    const [no, setNo] = useState(0);
    useEffect(() => {
        setTimeout(() => props.setLocalImageProfile(sessionStorage.getItem("image")), 1000);
    }, []);

    useEffect(() => {
        console.log(selectedOption);
    })

    const [selectedOption, setSelectedOption] = useState(null);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addProfile = async (e) => {
        e.preventDefault();
        const f = e.target;
        await Axios.post(mainUrl + "addProfile", {
            id: f.ids.value,
            name: f.name.value,
            father_name: f.father_name.value,
            email: f.email.value,
            gender_id: f.gender_id.value,
            status: f.status.value,
            image: f.image.value,
            remark: f.remark.value,
            user_type_id: f.user_type_id.value,

        }, {headers}).then(res => {
            if (f.ids.value == 0) {
                props.notifySuccess(' Added Successfully.');
            } else {
                props.notifySuccess(' Updated Successfully.');
            }
            document.querySelector(".main-panel")
                .scrollTo(0, 0);
            props.setList(res.data);
            props.setShowModal(false);
            props.setUpdateRecordStatus(false);
        }).catch(function (error) {
            props.notifyError("Error Server");

            document.getElementById("submit").disabled = false;
        });
    };

    return (
        <>
            {showUploadImage == true && <ImageSelect setLocalImageProfile={props.setLocalImageProfile}/>}
            <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
                <form onSubmit={(e) => {
                    document.getElementById("submit").disabled = true;
                    addProfile(e)
                }}>
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile">
                            New Profile
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <div className=" btn"
                                 style={{position: "relative", textAlign: "center", color: "white"}} alt="Image "
                                 title="Image">
                                <img src={props.localImageProfile != "" ? props.localImageProfile : image}
                                     style={{borderRadius: "50%", backgroundColor: "#8080805e!important"}}
                                     onClick={() => {
                                         if (!showUploadImage) {
                                             setShowUploadImage(true);
                                         } else {
                                             setShowUploadImage(false);
                                         }
                                     }} width="125" height="125"/>
                                <div style={{
                                    position: "absolute",
                                    top: "89%",
                                    left: "50%",
                                    transform: "translate(-50%, 50%)",
                                    whiteSpace: "nowrap"
                                }} className="text-primary">Select Image
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="image" id="image" value={props.localImageProfile}/>
                        <Row className="pt-5">
                            <Col className="pr-3" md="6">
                                <FormGroup>
                                    <label>Name <span className="text text-danger">*</span></label>
                                    <Input type="hidden" name="ids" id="ids"/>
                                    <Input required name="name" id="name" required placeholder="name"
                                           title="name" type="text"/>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup>
                                    <label>Last Name <span className="text text-danger">*</span></label>
                                    <Input required name="father_name" id="father_name"
                                           placeholder="Last Name" title="Last Name"
                                           type="text"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pr-3" md="6">
                                <FormGroup>
                                    <label>Gender <span className="text text-danger">*</span></label>
                                    <select required name="gender_id" id="gender_id" required
                                            className="form-control" title="Gender">
                                        <option value=""></option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                    </select>

                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup>
                                    <label>Active <span className="text text-danger">*</span></label>
                                    <select required name="status" id="status" required
                                            className="form-control" title="Status Type Id">
                                        <option value="1">Active</option>
                                        <option value="2">Non-Active</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup>
                                    <label> Email address <span
                                        className="text text-danger">*</span></label>
                                    <Input required name="email" id="email" placeholder="Email Address"
                                           type="email" title="Email Address"/>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6">
                                <FormGroup>
                                    <label>Phone </label>
                                    <input name="text" id="remark" className="form-control"
                                              placeholder="Phone Number"/>
                                </FormGroup>
                            </Col>
                            <Col className="pr-3" md="6" className="text-black">
                                <FormGroup>
                                    <label>User Type <span
                                        className="text text-danger">*</span></label>
                                    <select className="form-control" name="user_type_id" id="user_type_id">
                                        <option value=""></option>
                                        {
                                            UserTypeList.map((e, index) => (
                                            <option key={index} value={e.value}>{e.label}</option>
                                            ))
                                        }
                                    </select>
                                </FormGroup>
                            </Col>

                        </Row>

                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            title="Close"
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => {
                                props.setUpdateRecordStatus(false);
                                props.setShowModal(false)
                            }}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" id="submit" type="submit">
                            Submit
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
}

export default AddProfile;
