import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import AdminLayout from "layouts/Admin.js";
import './lang/i18n.js';

ReactDOM.render(
    // <React.StrictMode>
    <BrowserRouter  path={process.env.PUBLIC_URL + '/'}>
        <Switch>
            <Route path="/" render={(props) => <AdminLayout {...props} />}/>
            <Redirect to="/Dashboard"/>
        </Switch>

    </BrowserRouter>
    // </React.StrictMode>
        ,
    document.getElementById("root")
);
