
const farsiWords= {
    "totalAdminUser": "تعداد کاربران ادمین",
    "systemName": "سیستم مدیریتی تولیدات افغانستان"
    ,
    "dashboard": "داشبورد",
    "userProfile": "مشخصات کاربر",
    "setting": "تنظیمات",
    "report": "راپور"
}


export default farsiWords;
