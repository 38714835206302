import "./../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Card, CardHeader, CardTitle} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import toast from 'react-hot-toast';
import ListStory from "./ListStory";
import SearchStory from "./SearchStory";
import {EditorState} from "draft-js";

function Story() {


   const [list, setList] = React.useState([]);
      const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchStatus, setSearchStatus] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorStateFa, setEditorStateFa] = useState(EditorState.createEmpty());
    const [editorStatePa, setEditorStatePa] = useState(EditorState.createEmpty());

     const [needObject, setNeedObject] = React.useState({'project_id':0,'province_id':0,'branch_id':0,'class_id':0});
    const [searchParameter, setSearchParameter] = React.useState({
        'country':'',
        'phone': '',
        'email': '',
        'name': ''
    })


    useEffect(() => {
        loadData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    function getStorys() {
        return Axios.get(mainUrl + "getStory?page=1",{headers});
    }

    const loadData= async()=>{
        await Promise.all([getStorys()])
            .then(function (results) {
                setList(results[0].data);
                setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">

                <div>
                    <Row>
                        <Col md="12">
                            <Card className="card-">
                                <CardHeader style={{backgroundColor: "#80808029"}}>
                                    <CardTitle className="mr-4 ml-4">
                                        <div className="row">
                                            <div className="ml-right">
                                                <h3>  Story </h3>
                                            </div>
                                        </div>
                                    </CardTitle>
                                </CardHeader>
                                <SearchStory setSearchStatus={setSearchStatus} setSearchParameter={setSearchParameter} searchParameter={searchParameter} setList={setList} list={list} />
                            </Card>
                        </Col>
                    </Row>
                      <ListStory
                          editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
                          editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                          editorState={editorState} setEditorState={setEditorState} searchStatus={searchStatus} isLoading={isLoading} setList={setList} list={list} searchParameter={searchParameter}
                                   setSearchParameter={setSearchParameter} notifyError={notifyError}
                                   notifySuccess={notifySuccess}/>
                </div>

            </div>
        </>
    );


}


export default Story;
