import React, {useEffect, useState} from "react";
import ImageSelect from "./../Product/utils/imageSelect";
import {Button, Col, FormGroup, Modal, Row} from "react-bootstrap";
import image from "../../assets/img/photo.png";
import {Input} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common.js";
import "./utils/style.css"


import {convertToRaw} from 'draft-js';

import {Editor} from "react-draft-wysiwyg/dist/react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from 'draftjs-to-html';

function AddProduct(props) {
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // const [editorState, setEditorState] = useState(EditorState.createWithContent(
    //     ContentState.createFromBlockArray(
    //         convertFromHTML('<h1>My </h1> initial content.'))));


    const [showUploadImage, setShowUploadImage] = useState(false);
    const [provinceSubList, setProvinceSubList] = useState([]);

    const [projectId, setProjectId] = useState(0);
    const [attachFiles, setAttachFiles] = useState("");
    const [localImageCategory, setLocalImageCategory] = useState("");

    const [imageSet, setImageSet] = useState(1);

    const [downloadStatus, setDownloadStatus] = useState(false);
    const [branchSubList, setBranchSubList] = useState([]);
    const [classSubList, setClassSubList] = useState([]);
    const [classLoadStatus, setClassLoadStatus] = useState(true);

    const [no, setNo] = useState(0);
    const [tags, setTags] = useState([
        "HTML", "CSS", "JavaScript"
    ])

    useEffect(() => {
        props.setPicture({
            'pictureFirst': sessionStorage.getItem("pictureFirst"),
            'pictureSecond': sessionStorage.getItem("pictureSecond"),
            'pictureThird': sessionStorage.getItem("pictureThird")
        });

    }, [])

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const addProduct = async (e) => {
        e.preventDefault();
        const f = e.target;
        await Axios.post(mainUrl + "addProduct", {
            id: f.id.value,
            category_id: f.category_id.value,
            sub_category_id: f.sub_category_id.value,
            product_name: f.product_name.value,
            shipping_info: f.shipping_info.value,
            shipping_info_fa: f.shipping_info_fa.value,
            shipping_info_pa: f.shipping_info_pa.value,
            is_us: f.is_us.value,
            status_id: f.status_id.value,
            unit_type_id: f.unit_type_id.value,
            description: draftToHtml(convertToRaw(props.editorState.getCurrentContent())),
            similar: tags,
            picture_first: f.picture_first.value,
            picture_second: f.picture_second.value,
            picture_third: f.picture_third.value,

            name_fa: e.target.name_fa.value,
            description_fa:  draftToHtml(convertToRaw(props.editorStateFa.getCurrentContent())),

            name_pa: e.target.name_pa.value,
            description_pa:  draftToHtml(convertToRaw(props.editorStatePa.getCurrentContent())),

        }, {headers}).then(res => {
            if (f.id.value == 0) {
                props.notifySuccess(' Added Successfully.');
                let newData = props.data;
                props.setList(newData.push(res.data));
            } else {
                props.notifySuccess(' Updated Successfully.');
                var newData = props.list.data.filter(e => {
                    if (e.id == res.data.id) {
                        return res.data;
                    } else {
                        return e;
                    }
                });

                var someProperty = {...props.list}
                someProperty.data = newData;
                props.setList(someProperty);
            }
            document.querySelector(".main-panel").scrollTo(0, 0);
            // props.setList(res.data);
            props.setShowModal(false);
            props.setUpdateRecordStatus(false);
        }).catch(function (error) {
            props.notifyError(error);
        });
    };

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        setTags([...tags, value])
        e.target.value = ''
    }

    function removeTag(index) {
        setTags(tags.filter((el, i) => i !== index))
    }

    function onEditorStateChange(editorState) {
        props.setEditorState(editorState);
    };

    function onEditorStateChangeFa(editorState) {
        props.setEditorStateFa(editorState);
    };

    function onEditorStateChangePa(editorState) {
        props.setEditorStatePa(editorState);
    };
    return (
        <div>
            {showUploadImage == true && <ImageSelect
                imageSet={imageSet} setPicture={props.setPicture} picture={props.picture}/>}
            <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
                <form onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }} onSubmit={(e) => {
                    document.getElementById("submit").disabled = true;
                    addProduct(e)
                }}>
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile text-info">
                            New Product
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="hideInput">
                            <Input type="text" className="hidden" required name="id" id="id" defaultValue="0"/>
                            <Input type="text" name="picture_first" id="picture_first"
                                   defaultValue={props.picture.pictureFirst}/>
                            <Input type="text" name="picture_second" id="picture_second"
                                   defaultValue={props.picture.pictureSecond}/>
                            <Input type="text" name="picture_third" id="picture_third"
                                   defaultValue={props.picture.pictureThird}/>
                        </div>
                        <div style={{height: '700px', overflowY: 'auto', marginTop: '20px', paddingBottom: '50px   '}}>
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Category <span className="text text-danger">*</span></label>
                                        <select required name="category_id" id='category_id'
                                                className="form-control" title="Select Category">
                                            <option value=""></option>
                                            {props.categoryList.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Sub Category <span className="text text-danger">*</span></label>
                                        <select required name="sub_category_id" id='sub_category_id'
                                                className="form-control" title="Select Sub Category">
                                            <option value=""></option>
                                            {props.subCategoryList.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Product Name <span className="text text-danger">*</span></label>
                                        <Input required name="product_name" id="product_name" placeholder="Product Name"
                                               title="name" type="text"/>
                                    </FormGroup>
                                </Col>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Product Name Farsi<span className="text text-danger">*</span></label>
                                        <Input required name="name_fa" id="name_fa" placeholder="Product Name"
                                               title="name" type="text"/>
                                    </FormGroup>
                                </Col>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Product Name Pashto<span className="text text-danger">*</span></label>
                                        <Input required name="name_pa" id="name_pa" placeholder="Product Name"
                                               title="name" type="text"/>
                                    </FormGroup>
                                </Col>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Shipping Information <span className="text text-danger">*</span></label>
                                        <Input required name="shipping_info" id="shipping_info" placeholder="Shipping Information"
                                               title="Shipping Information" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Shipping Information Farsi<span className="text text-danger">*</span></label>
                                        <Input required name="shipping_info_fa" id="shipping_info_fa" placeholder="Shipping Information"
                                               title="Shipping Information" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Shipping Information Pashto<span className="text text-danger">*</span></label>
                                        <Input required name="shipping_info_pa" id="shipping_info_pa" placeholder="Shipping Information"
                                               title="Shipping Information" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Status <span className="text text-danger">*</span></label>
                                        <select required name="status_id" id="status_id" required
                                                className="form-control" title="Status Type Id">
                                            <option value="1">Active</option>
                                            <option value="2">Non-Active</option>
                                        </select>
                                    </FormGroup>
                                </Col>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Unit Type <span className="text text-danger">*</span></label>
                                        <select required name="unit_type_id" id='unit_type_id'
                                                className="form-control" title="Select position">
                                            <option value=""></option>
                                            {props.unitTypeList.map((e) => (
                                                <option key={e.id}
                                                        value={e.id}>{e.name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="4">
                                    <FormGroup>
                                        <label>Total Quantity <span className="text text-danger">*</span></label>
                                        <Input disabled name="total_quantity" defaultValue="0" id="total_quantity"
                                               placeholder="Total Quantity"
                                               title="Total Quantity" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="4">
                                    <FormGroup>
                                        <label>Total Remain <span className="text text-danger">*</span></label>
                                        <Input disabled name="total_remain" defaultValue="0" id="total_remain"
                                               placeholder="Total Remain"
                                               title="Total Remain" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="4">
                                    <FormGroup>
                                        <label>Unit Price <span className="text text-danger">*</span></label>
                                        <Input disabled name="price" defaultValue="0" id="price"
                                               placeholder="Unit Price"
                                               title="Unit Price" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-3" md="6">
                                    <FormGroup>
                                        <label>United State Product <span className="text text-danger">*</span></label>
                                        <select name="is_us" id="is_us" className="form-control"  title="Is United States">
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                        </select>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col className="pr-3" md="12">
                                    <FormGroup>
                                        <br/>
                                        <label>Description : </label>
                                        <div className="border border-1 border-dark text-info">
                                            <Editor
                                                editorState={props.editorState}
                                                toolbarClassName="toolbarClassName col-md-8 text-info"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                            <textarea className="hideInput" name="description" id="description"
                                                      defaultValue={draftToHtml(convertToRaw(props.editorState.getCurrentContent()))}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*farsi description*/}
                            <Row>
                                <Col className="pr-3" md="12">
                                    <FormGroup>
                                        <br/>
                                        <label>Description Farsi: </label>
                                        <div className="border border-1 border-dark text-info">
                                            <Editor
                                                editorState={props.editorStateFa}
                                                toolbarClassName="toolbarClassName col-md-8 text-info"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChangeFa}
                                            />
                                            <textarea className="hideInput" name="description_fa" id="description_fa"
                                                      defaultValue={draftToHtml(convertToRaw(props.editorStateFa.getCurrentContent()))}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/*pashto description*/}
                            <Row>
                                <Col className="pr-3" md="12">
                                    <FormGroup>
                                        <br/>
                                        <label>Description Pashto : </label>
                                        <div className="border border-1 border-dark text-info">
                                            <Editor
                                                editorState={props.editorStatePa}
                                                toolbarClassName="toolbarClassName col-md-8 text-info"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChangePa}
                                            />
                                            <textarea className="hideInput" name="description_pa" id="description_pa"
                                                      defaultValue={draftToHtml(convertToRaw(props.editorStatePa.getCurrentContent()))}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row style={{display: 'none'}}>
                                <Col style={{display: 'none'}}>
                                    <br/>
                                    <br/>
                                    <label className="text-info">Similarity Words:</label>
                                    <div className="inputs_tags-input-container text-info">
                                        {tags.map((tag, index) => (
                                            <div className="inputs_tag-item" key={index}>
                                                <span className="text">{tag}</span>
                                                <span className="inputs_close"
                                                      onClick={() => removeTag(index)}>&times;</span>
                                            </div>
                                        ))}
                                        <input onKeyDown={handleKeyDown} type="text" className="inputs_tags-input"
                                               placeholder="Type somthing"/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="text-info"><Col><br/><br/>Picture List <br/> <br/></Col> </Row>
                            <Row>
                                <Col md={5}>
                                    <div className="text-center p-3 border border-1 border-info rounded-3">
                                         <span title="Delete Image" className="bg-danger text-light" style={{
                                             position: "relative",
                                             borderRadius: "25%",
                                             cursor: "pointer",
                                             top: "-30px",
                                             left: "150px",
                                         }}
                                               onClick={async () => {
                                                   await sessionStorage.setItem("pictureFirst", "");
                                                   await props.setPicture({...props.picture, 'pictureFirst': ""});
                                                   document.getElementById('picture_first').value = "";
                                               }}>
                                            &nbsp;&nbsp;X&nbsp;&nbsp;</span>

                                        <div
                                            style={{position: "relative", textAlign: "center", color: "white"}}
                                            alt="Image "
                                            title="Change Image">
                                            <img
                                                src={props.picture.pictureFirst != "" ? props.picture.pictureFirst : image}
                                                style={{
                                                    backgroundColor: "#8080805e!important",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => {
                                                    setImageSet(1);
                                                    if (!showUploadImage) {
                                                        setShowUploadImage(true);
                                                    } else {
                                                        setShowUploadImage(false);
                                                    }
                                                }} width="400" height="auto"/>
                                            <div style={{
                                                position: "absolute",
                                                top: "85%",
                                                left: "50%",
                                                transform: "translate(-50%, 50%)",
                                                whiteSpace: "nowrap"
                                            }} className="text-primary">Select Image <br/> (400px) X (400px) <br/> Resize Image: <a href="https://www.resizepixel.com/" target="_blank">resizepixel.com</a>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            title="Close"
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => {
                                props.setUpdateRecordStatus(false);
                                props.setShowModal(false)
                            }}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" id="submit" type="submit">
                            Submit
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}

export default AddProduct;
