import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Dropdown, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {ContentState, convertFromHTML, EditorState} from 'draft-js';
import ChangePrice from "./ChangePrice";
import htmlToDraft from "draftjs-to-html";

function ListProduct(props) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showChangeModal, setShowChangeModal] = React.useState(false);
    const [productDetails, setProductDetails] = React.useState([]);
    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [ristList, setRistList] = React.useState([]);

    const [pageNo, setPageNo] = React.useState(2);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        if (props.list.data != undefined) {
            setRistList(props.list.data)
        }
    });

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteProduct", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Successfully deleted");
            const lists = res.data;
            props.setList(lists);
        }).catch((error) => {
            props.notifyError(error.response.data.message);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }
    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchProduct?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getProducts?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

        setRecordId(0);
        setShowDeleteModal(false);
    }
    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile text-info">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3 text-info">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }
    let rowNo = 1;
    return (
        <>
            <DeleteItem/>
            {showChangeModal == true &&
            <ChangePrice currencyList={props.currencyList} product={productDetails} setProduct={setProductDetails} showChangeModal={showChangeModal} setShowChangeModal={setShowChangeModal}/>
            }
            <div className="text-center content-center" style={{position: 'absolute',marginLeft:'40%',marginTop:'15%'}}>
                <Spinner animation="border" variant="info"/>
            </div>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> Product List </h2>
                                    </div>
                                    <div className="ml-end">
                                        <Button title="Add New Record" className="btn-info" variant=""
                                                onClick={() => {
                                                    props.setShowModal(true);
                                                    sessionStorage.setItem("pictureFirst", "");
                                                    sessionStorage.setItem("pictureSecond", "");
                                                    sessionStorage.setItem("pictureThird", "");

                                                }}>
                                            <span className="nc-icon nc-simple-add"/>
                                        </Button>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>
                        {props.isLoading === false ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : (
                            <CardBody>
                                <Table>
                                    <thead className="text-info">
                                    <tr>
                                        <th>No</th>
                                        <th>Category</th>
                                        <th>Sub Category</th>
                                        <th>United State</th>
                                        <th>Product Name</th>
                                        {/*<th>Description</th>*/}
                                        <th>Status</th>
                                        <th className="text-right ">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {ristList.map((e, index) => (
                                        <tr key={index}>

                                            <td>{rowNo++}</td>
                                            <td style={{width: "20%"}}>{e.category_name}</td>
                                            <td style={{width: "20%"}}>{e.sub_category_name}</td>
                                            <td style={{width: "20%"}}>
                                                {e.is_us==true && <i className="nc-icon nc-check-2"/>}
                                                {e.is_us==false && <i className="nc-icon nc-simple-remove"/>}

                                            </td>
                                            <td style={{width: "20%"}}>{e.name}</td>
                                            {/*<td style={{width: "70%"}}>*/}
                                            {/*    <div  dangerouslySetInnerHTML={{__html: e.description}}></div>*/}
                                            {/*</td>*/}
                                            <td style={{whiteSpace: "nowrap"}}>
                                                {e.status_id === 1 &&
                                                <span className="badge badge-primary ">Active</span>
                                                }
                                                {e.status_id === 2 &&
                                                <span className="badge badge-warning ">Non Active</span>
                                                }
                                            </td>
                                            <td className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic"
                                                                     className='outline-info dropDownClass'>
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Edit"
                                                                style={{whiteSpace: "nowrap"}}
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                                onClick={async () => {
                                                                    await Axios.post(mainUrl + "getPhoto", {
                                                                        ref_id: e.id,
                                                                        entity_id: 3,
                                                                    }, {headers}).then(async (res) => {
                                                                        await props.setShowModal(true);
                                                                        sessionStorage.setItem("pictureFirst", res.data.length >= 1 ? res.data[0] : "");
                                                                        sessionStorage.setItem("pictureSecond", res.data.length >= 2 ? res.data[1] : "");
                                                                        sessionStorage.setItem("pictureThird", res.data.length >= 3 ? res.data[2] : "");
                                                                        props.setPicture({
                                                                            'pictureFirst': res.data.length >= 1 ? res.data[0] : ""
                                                                            ,
                                                                            'pictureSecond': res.data.length >= 2 ? res.data[1] : ""
                                                                            ,
                                                                            'pictureThird': res.data.length >= 3 ? res.data[2] : ""
                                                                        });

                                                                        document.getElementById('id').value = e.id;
                                                                        document.getElementById('category_id').value = e.category_id;
                                                                        document.getElementById('sub_category_id').value = e.sub_category_id;
                                                                        document.getElementById('product_name').value = e.name;
                                                                        document.getElementById('status_id').value = e.status;
                                                                        document.getElementById('unit_type_id').value = e.unit_type_id;
                                                                        document.getElementById('total_quantity').value = e.total_quantity;
                                                                        document.getElementById('total_remain').value = e.remain_quantity;
                                                                        document.getElementById('shipping_info').value = e.shipping_info;
                                                                        document.getElementById('shipping_info_fa').value = e.shipping_info_fa;
                                                                        document.getElementById('shipping_info_pa').value = e.shipping_info_pa;
                                                                        document.getElementById('is_us').value = e.is_us;
                                                                        document.getElementById('price').value = e.price;

                                                                        document.getElementById('name_fa').value = e.name_fa;
                                                                        // document.getElementById('description_fa').value = e.description_fa;

                                                                        document.getElementById('name_pa').value = e.name_pa;
                                                                        // document.getElementById('description_pa').value = e.description_pa;

                                                                        props.setEditorState(EditorState.createWithContent(
                                                                            ContentState.createFromBlockArray(convertFromHTML(e.description))));

                                                                        props.setEditorStateFa(EditorState.createWithContent(
                                                                            ContentState.createFromBlockArray(convertFromHTML(e.description_fa))));

                                                                        props.setEditorStatePa(EditorState.createWithContent(
                                                                            ContentState.createFromBlockArray(convertFromHTML(e.description_pa))));
                                                                    }).catch(function (error) {
                                                                        // notifyError(error.response.data.message);
                                                                    });
                                                                }}>

                                                            <span className="nc-icon nc-paper"/>&nbsp;&nbsp;
                                                            Edit
                                                        </Button>
                                                        <Button title="Change Price or Quantity" style={{whiteSpace:'nowrap'}}
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setProductDetails(e);
                                                                    setRecordId(e.id);
                                                                    setShowChangeModal(true);
                                                                }}>
                                                            <span
                                                                className="nc-icon nc-tag-content "/>&nbsp;&nbsp;
                                                            Change Price or Quantity
                                                        </Button>

                                                        <Button title="Delete Record" style={{whiteSpace:'nowrap'}}
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    setShowDeleteModal(true);
                                                                }}>
                                                            <span
                                                                className="nc-icon nc-simple-delete "/>&nbsp;&nbsp;
                                                            Delete
                                                        </Button>

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </Table>
                                <div className="page-content-center">
                                    {loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="info"/>
                                            </div>
                                        ) :
                                        (props.list.last_page >= pageNo ?
                                            <button className="btn  bg-light text-info" title="Load More"
                                                    onClick={(pageNumber => {
                                                        nextPageLoadList(pageNumber);
                                                    })}>
                                                <span className="nc-icon nc-simple-add text-info"/>
                                            </button>

                                            : "")}
                                </div>
                            </CardBody>
                        )
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListProduct;
