import {Modal} from "react-bootstrap";
// import CloseIcon from "@mui/icons-material/Close";
import WashLists from "./WashLists";
import React from "react";

function WashListModel(props) {
    return (
        <>
            <Modal show={props.showWashListModel} size="xl" onHide={() => props.setShowWashListModel(false)}
                   style={{height: '800px', top: '10vh'}}>
                <Modal.Body>
                    <div className="modal-profile text-warning p-2" style={{position: 'absolute', right: '10px'}}>
                        {/*<CloseIcon fontSize='large' role="button"*/}
                        {/*           style={{backgroundColor: 'rgb(0,0,0)', borderRadius: '50%', right: '0',color:'rgb(255,255,266)'}}*/}
                        {/*           onClick={() => props.setShowWashListModel(false)}/>*/}
                    </div>

                    <div className="h3 text-info">Cart</div>
                    <WashLists mainRecord={props.mainRecord} data={props.data} setData={props.setData} setShowWashListModel={props.setShowWashListModel}
                               setMainRecord={props.setMainRecord}
                               setRecordList={props.setRecordList}
                               recordList={props.recordList}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WashListModel;
