import React, {useState} from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import {generateDownload} from "./cropImage";
import {Modal} from "react-bootstrap";


export default function ImageSelect(props) {
    // image
    const inputRef = React.useRef();

    const [showUploadImage, setShowUploadImage] = useState(true);
    const triggerFileSelectPopup = () => inputRef.current.click();

    const [image, setImage] = React.useState(null);
    const [croppedArea, setCroppedArea] = React.useState(null);
    const [crop, setCrop] = React.useState({x: 0, y: 0});
    const [zoom, setZoom] = React.useState(1);
    const [doneStatus, setDoneStatus] = React.useState(true);

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.addEventListener("load", () => {
                setImage(reader.result);
                setDoneStatus(false);
            });
        }
    };

    const onDownload = async() => {
        // localStorage.removeItem("image");

        await generateDownload(image, croppedArea);
        await setTimeout(()=>props.setLocalImageStory(sessionStorage.getItem("storyImage")), 1000);
         setShowUploadImage(false);
         props.setShowUploadImage(false);

    };
    // end image

    return (
        <>
            <Modal style={{boxShadow: '0px 0px 3px 1px gray', paddingTop: "20px",zIndex:"2000"}} show={showUploadImage}
                   backdrop="static" size="md" onHide={() => setShowUploadImage(false)}>
                <Modal.Body style={{
                    backgroundImage: `url('https://cdn-icons-png.flaticon.com/64/3342/3342137.png')`
                    , backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundColor: '#80808070'
                }}
                >
                    <div className='container-cropper'>
                        {image ? (
                            <>
                                <div className='cropper'>
                                    <Cropper
                                        image={image}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                    />
                                </div>


                            </>
                        ) : null}
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </Modal.Body>
                <div className='slider'>
                    <Slider
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onChange={(e, zoom) => setZoom(zoom)}
                    />
                </div>
                <Modal.Footer>
                    <div className='content'>

                        <input
                            type='file'
                            accept='image/*'
                            ref={inputRef}
                            onChange={onSelectFile}
                            style={{display: "none"}}
                        />

                        <Button
                        className='text-left'
                            variant='contained'
                            color='primary'
                            onClick={triggerFileSelectPopup}
                            style={{marginRight: "250px"}}
                        >
                            &nbsp;  <span className="nc-icon nc-image "/>&nbsp;
                        </Button>

                        <Button variant='contained'  color='secondary' onClick={()=>setShowUploadImage(false)} style={{marginRight: "10px"}}>
                            &nbsp; <span className="nc-icon nc-simple-remove "/>&nbsp;
                        </Button>
                        <Button variant='contained' disabled={doneStatus} color='secondary' className='bg-success text-light'onClick={onDownload}>
                            &nbsp; <span className="nc-icon nc-check-2 "/>&nbsp;
                        </Button>
                    </div>
                </Modal.Footer>

            </Modal>
        </>
    );


}
