import React from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, CardBody, Col, Input, Row} from "reactstrap";
import {Form, FormGroup} from "react-bootstrap";

function SearchItem(props) {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    var curr = new Date();
    curr.setDate(curr.getDate() - 30);
    var date = curr.toISOString().substr(0, 10);
    var curra = new Date();
    curra.setDate(curra.getDate()-0);
    var datea = curra.toISOString().substr(0, 10);


    const setReport = async (e) => {
        e.preventDefault();
        props.setList([]);
        props.setReportType(e.target.value);
    }
    const generateReport = async (e) => {
        e.preventDefault();
        const f = e.target;

        if (props.reportType == 1) {

            props.setIsLoading(1);
            const hData = await Axios.post(mainUrl + "briefReport", {}, {headers});
            const listsD = hData.data;
            if (listsD != null) {
                props.setList(listsD);
            } else {
                props.setList([]);
            }
            props.setIsLoading(1);
        } else if (props.reportType == 2) {
            const {data} = await Axios.post(mainUrl + "orderReport", {
                status: f.status.value,
                start_date: f.start_date.value,
                end_date: f.end_date.value
            }, {headers});
            const lists = data;
            props.setList(lists);
            props.setIsLoading(1);
        } else if (props.reportType == 3) {
            const {data} = await Axios.post(mainUrl + "customerReport", {
                email: f.email.value
            }, {headers});
            const lists = data;
            props.setList([lists]);
            props.setIsLoading(1);
        }
    }
    return (
        <>
            <CardBody className="mr-4 ml-4">
                <Form onSubmit={(e) => generateReport(e)}>
                    <Row>
                        <Col className="px-1" md="12">
                            <FormGroup>
                                <label>Report Type <span className="text text-danger">*</span></label>
                                <select name="report_type_id" id="report_type_id" title="Report type"
                                        defaultValue={props.reportType}
                                        className="form-control" onChange={(e) => setReport(e)}>
                                    <option value="0"></option>
                                    <option value="1">Brief Report</option>
                                    <option value="2">Order Report</option>
                                    <option value="3">Customer Report</option>

                                </select>
                            </FormGroup>
                        </Col>

                        {props.reportType == 2 && (
                            <>
                                <Col className="px-1" md="4">
                                    <FormGroup>
                                        <label>Status <span className="text text-danger">*</span></label>
                                        <select name="status" id="status" title="Select Status"
                                                className="form-control">
                                            <option value="2">New</option>
                                            <option value="3">Process</option>
                                            <option value="4">Done</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </>
                        )}
                        {(props.reportType == 3) && (
                            <>
                                <Col className="px-1" md="4">
                                    <FormGroup>
                                        <label>Email Address <span className="text text-danger">*</span></label>
                                        <input type="email" name="email" className="form-control"/>
                                    </FormGroup>
                                </Col>
                            </>
                        )}
                        {(props.reportType == 2 || props.reportType == 2) && (
                            <>
                                <Col className="px-1" md="4">
                                    <FormGroup>
                                        <label>Start Date <span className="text text-danger">*</span></label>
                                        <input type="date" name="start_date" className="form-control" defaultValue={date}/>
                                    </FormGroup>
                                </Col>
                                <Col className="px-1" md="4">
                                    <FormGroup>
                                        <label>End Date <span className="text text-danger">*</span></label>
                                        <input type="date" name="end_date" className="form-control"  defaultValue={datea}/>
                                    </FormGroup>
                                </Col>
                            </>
                        )}
                    </Row>

                    <Row>
                        <div className="ml-end">
                            <Button title="Generate Report" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Generate
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </Form>
            </CardBody>
        </>
    );
}


export default SearchItem;
