// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import Axios from "axios";
import {dataURLtoFile, mainUrl, toBase64} from "../../../common/common";
import {Button, Col, Dropdown, Form, FormGroup, Modal, Row, Spinner, Table} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast from "react-hot-toast";
import image from "../../../assets/img/photo.png";
import ImageSelect from "../utils/imageSelect";
import {SliderPicker} from 'react-color';

export default function Category(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [backgroundColor, setBackgroundColor] = React.useState("");
    const [list, setList] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [attachFiles, setAttachFiles] = useState("");
    const [downloadStatus, setDownloadStatus] = useState(false);
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getCategory", {headers});
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };

    const addCategory = async (e) => {
        e.preventDefault();
        const f = e.target;
        let attachFile = "";
        if (f.banner_image.files[0] != null && f.banner_image.files[0] != "") {
            attachFile = await toBase64(f.banner_image.files[0]);
        }
        await Axios.post(mainUrl + "addCategory", {
            id: e.target.catId.value,
            name: e.target.name.value,
            description: e.target.description.value,

            name_fa: e.target.name_fa.value,
            description_fa: e.target.description_fa.value,

            name_pa: e.target.name_pa.value,
            description_pa: e.target.description_pa.value,

            image: e.target.image.value,
            banner_image: attachFile,
            backgroundColor: e.target.backgroundColor.value
        }, {headers}).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setShowModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setShowModal(false);
        });
    };

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteCategory", {
            id: recordId
        }, {headers}).then(res => {
            notifySuccess("Successfully deleted");
            const lists = res.data;
            setList(lists);
        }).catch((error) => {
            notifyError(error.response.data.message);

        });
        setRecordId(0);
        setShowDeleteModal(false);
    }
    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">
                {isLoading === false ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="info"/>
                    </div>
                ) : (<div>
                        <AddItem/>
                        <TableData/>
                        <DeleteItem/>
                    </div>
                )}
            </div>
        </>
    );

    function AddItem() {
        const [showUploadImage, setShowUploadImage] = useState(false);
        const [localImageCategory, setLocalImageCategory] = useState("");
        const [backGround, setBackGround] = useState("");
        const handleChangeComplete = (color) => {
            setBackGround(color.hex);
        };
        useEffect(() => {
            setBackGround(backgroundColor);
            setTimeout(() => setLocalImageCategory(sessionStorage.getItem("categoryImage")), 1000);
        }, []);
        const downloadFile = async () => {
            const f = document.getElementById('catId').value;
            await Axios.post(mainUrl + "getBannerImage", {
                id: f
            }, {headers}).then(res => {
                console.log(res.data);
                var filePDf = dataURLtoFile('data:image/jpeg;base64,' + res.data, 'img.jpg');
                const previewUrl = window.URL.createObjectURL(filePDf);
                const anchor = document.createElement("a");
                anchor.download = "img.jpg";
                anchor.href = URL.createObjectURL(filePDf);

                anchor.click();
                window.URL.revokeObjectURL(previewUrl);
            }).catch(function (error) {
                props.notifyError(error.response.data.message);
            });
            // setDownloadStatus(false);
        }

        return (
            <div>
                {showUploadImage == true &&
                <ImageSelect setShowUploadImage={setShowUploadImage} setLocalImageCategory={setLocalImageCategory}/>}
                <Modal show={showModal} backdrop="static" size="xl" onHide={() => setShowModal(false)}>
                    <Form onSubmit={(e) => {
                        document.getElementById("submit").disabled = true;
                        addCategory(e)
                    }}>
                        <div className="hideInput">
                            <Input type="text" className="hidden" required name="catId" id="catId"
                                   defaultValue="0"/>
                            <Input type="text" name="image" id="image" defaultValue={localImageCategory}/>
                        </div>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile text-info">
                                Add New Category
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{height:"600px!important"}}>
                           
                            <Row className="pr-3">

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Category name <span className="text text-danger">*</span></label>
                                        <Input required name="Category Name" title="Category Name" id="name"
                                               placeholder="name" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="12">
                                    <FormGroup>
                                        <label>Description <span className="text text-danger">*</span></label>
                                        <br/>
                                        <textarea required row="10" className="form-control"
                                                  style={{maxHeight: "500px!important"}} name="description"
                                                  title="Description" id="description"
                                                  placeholder="Description" type="text">
                                        </textarea>
                                    </FormGroup>
                                    <p className="text-danger" style={{fontSize:"10pt"}}> 250 max character</p>
                                </Col>
                            </Row>
                            <hr/>
                            {/*farsi*/}
                            <Row className="pr-3">
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Name Farsi <span className="text text-danger">*</span></label>
                                        <Input required name="category_fa" title="Category Name" id="name_fa"
                                               placeholder="name" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="12">
                                    <FormGroup>
                                        <label>Description Farsi <span className="text text-danger">*</span></label>
                                        <br/>
                                        <textarea required row="10" className="form-control"
                                                  style={{maxHeight: "500px!important"}} name="description_fa"
                                                  title="Description" id="description_fa"
                                                  placeholder="Description" type="text">
                                        </textarea>
                                    </FormGroup>
                                    <p className="text-danger" style={{fontSize:"10pt"}}> 250 max character</p>
                                </Col>
                            </Row>
                            <hr/>
                            {/*pashto*/}
                            <Row className="pr-3">
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Name Pashto <span className="text text-danger">*</span></label>
                                        <Input required name="category_pa" title="Category Name" id="name_pa"
                                               placeholder="name" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-3" md="12">
                                    <FormGroup>
                                        <label>Description Pashto <span className="text text-danger">*</span></label>
                                        <br/>
                                        <textarea required row="10" className="form-control"
                                                  style={{maxHeight: "500px!important"}} name="description_pa"
                                                  title="Description" id="description_pa"
                                                  placeholder="Description" type="text">
                                        </textarea>
                                        <p className="text-danger" style={{fontSize:"10pt"}}> 250 max character</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Background Color <span className="text text-danger">*</span></label>
                                        <br/>
                                        <div style={{padding: "10px!important", border: "1px solid black!important"}}>
                                            <SliderPicker
                                                color={backGround}
                                                onChangeComplete={handleChangeComplete}
                                            />
                                        </div>
                                        <input type="hidden" name="backgroundColor" defaultValue={backGround}/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <br/>
                                    <div style={{width: '100px', height: '100%', backgroundColor: backGround}}>
                                        COLOR
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <br/>
                                </Col>
                            </Row>
                            <Row className="text-info">
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Banner Image <span className="text text-danger">*</span></label>
                                        <input name="banner_image" id='banner_image' accept=".jpg" type="file"
                                               title="Image"
                                               className="form-control"/>
                                        Select .JPG file only.<br/>
                                        Maximum upload file size :1 MB <br/>
                                        (1920px) X (680px) <br/>
                                        Resize Image:  <a href="https://www.resizepixel.com/" target="_blank">resizepixel.com</a>
                                    </FormGroup>
                                    {attachFiles != "" &&
                                    <div role="button" className="btn btn-warning" onClick={()=>downloadFile()}>
                                        {downloadStatus === true ? ("Loading . . . ") : ("Download File")}
                                    </div>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-center">
                                        <div
                                            style={{position: "relative", textAlign: "center", color: "white"}}
                                            alt="Image "
                                            title="Image">
                                            <img src={localImageCategory != "" ? localImageCategory : image}
                                                 style={{
                                                     borderRadius: "5%",
                                                     backgroundColor: "#8080805e!important",
                                                     cursor: "pointer"
                                                 }}
                                                 onClick={() => {
                                                     if (!showUploadImage) {
                                                         setShowUploadImage(true);
                                                     } else {
                                                         setShowUploadImage(false);
                                                     }
                                                 }} width="400" height="400"/>
                                            <div style={{
                                                position: "absolute",
                                                top: "85%",
                                                left: "50%",
                                                transform: "translate(-50%, 50%)",
                                                whiteSpace: "nowrap"
                                            }} className="text-primary">Select Image <br/> (400px) X (400px) <br/> Resize Image: <a href="https://www.resizepixel.com/" target="_blank">resizepixel.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round btn-info" id="submit" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </div>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h2> Category List </h2>
                                        </div>
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-info" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setShowModal(true);
                                                        sessionStorage.setItem("categoryImage", "");
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-info">
                                    <tr>
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "30%"}}>{e.name}</td>
                                            <td style={{width: "70%"}}>{e.description}</td>
                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic"
                                                                     className='outline-info dropDownClass'>
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Edit"
                                                                onClick={async () => {
                                                                    await Axios.post(mainUrl + "getPhoto", {
                                                                        ref_id: e.id,
                                                                        entity_id: 1,
                                                                    }, {headers}).then(async (res) => {
                                                                        setRecordId(e.id);
                                                                        if (e.banner_image != null && e.banner_image != "") {
                                                                            setAttachFiles(e.banner_image);
                                                                        }
                                                                        setBackgroundColor(e.background_color);

                                                                        await setShowModal(true);
                                                                        sessionStorage.setItem("categoryImage", res.data);
                                                                        document.getElementById('name').value = e.name;
                                                                        document.getElementById('description').value = e.description;

                                                                        document.getElementById('name_fa').value = e.name_fa;
                                                                        document.getElementById('description_fa').value = e.description_fa;

                                                                        document.getElementById('name_pa').value = e.name_pa;
                                                                        document.getElementById('description_pa').value = e.description_pa;

                                                                        document.getElementById('catId').value = e.id;

                                                                    }).catch(function (error) {
                                                                        notifyError(error.response.data.message);
                                                                    });
                                                                }} className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button">
                                                            <span className="nc-icon nc-paper"/>&nbsp;&nbsp;
                                                            Edit
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                        >
                                                            <span className="nc-icon nc-simple-delete "/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                        <br/>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="md" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile text-gray">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3 text-gray">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round btn-info" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

};
