import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, FormGroup, Modal, Row, Spinner} from "react-bootstrap";
import toast from "react-hot-toast";

function ChangePrice(props) {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const [priceList, setPriceList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        loadPrice();

    }, [props.showChangeModal]);

    async function loadPrice() {
        const {data} = await Axios.post(mainUrl + "getPrice", {
            "product_id": props.product.id
        }, {headers});
        const lists = data;
        setPriceList(lists.result);
        setIsLoading(true);

    }

    const onChangePrice = async (e) => {
        e.preventDefault();
        if(e.target.new_quantity.value>=props.product.total_quantity*(-1) &&  e.target.new_price.value>0) {
            await Axios.post(mainUrl + "addPrice", {
                "product_id": props.product.id,
                "quantity": e.target.new_quantity.value,
                "price": e.target.new_price.value,
                "currency_id": e.target.currency_id.value,
            }, {headers}).then(res => {
                setPriceList(res.data.result);
                props.setProduct({...props.product,
                    'total_quantity':res.data.total_quantity,
                    'remain_quantity':res.data.total_remain
                });
            }).catch(error => {

            });
        }else{
            notifyError("Price must be greater than zero. \n and Quantity must be greater than ( -"+ props.product.total_quantity +" )");
        }
    }

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    return (
        <>
            <Modal show={props.showChangeModal} backdrop="static" size="xl"
                   onHide={() => props.setShowChangeModal(false)}>
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile text-info">
                        Change Price or Add New Quantity
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row className="pr-3 text-info"  >
                        <Row>
                            <Col style={{padding: '15px',fontWeight:'bold'}}>Category name</Col>
                            <Col style={{padding: '15px'}}>{props.product.category_name}</Col>
                            <Col style={{padding: '15px',fontWeight:'bold'}}>Sub Category name</Col>
                            <Col style={{padding: '15px'}}>{props.product.sub_category_name}</Col>
                            <Col style={{padding: '15px',fontWeight:'bold'}}>Product Name</Col>
                            <Col style={{padding: '15px'}}>{props.product.name}</Col>
                        </Row>
                        <Row>
                            <Col style={{padding: '15px',fontWeight:'bold'}}>Total Quantity</Col>
                            <Col
                                style={{padding: '15px'}}>{props.product.total_quantity == null ? 0 : props.product.total_quantity}</Col>
                            <Col style={{padding: '15px',fontWeight:'bold'}}>Remain Quantity</Col>
                            <Col
                                style={{padding: '15px'}}>{props.product.remain_quantity == null ? 0 : props.product.remain_quantity}</Col>
                            <Col style={{padding: '15px'}}></Col>
                            <Col style={{padding: '15px'}}></Col>
                        </Row>
                    </Row>
                    <br/>
                    <form onSubmit={(e) => onChangePrice(e)}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label>Quantity</label>
                                <input required type="number" className="form-control" name="new_quanity" defaultValue="0"
                                       id="new_quantity" title="New Price"/>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <label>New Price</label>
                                <input required type="number" className="form-control" name="new_price" defaultValue="0"
                                       id="new_price" title="New Price"/>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <label>Currency Type</label>
                                <select required required name="currency_id" id='currency_id'
                                        className="form-control" title="Select Currency">
                                    <option value=""></option>
                                    {props.currencyList.map((e) => (
                                        <option key={e.id}
                                                value={e.id}>{e.name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>
                        <Row>
                            <Col className="ml-end">
                                <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                    Add
                                </Button>
                            </Col>
                        </Row>

                    </form>
                    <Row className="add_scroll" style={{height: '300px'}}>
                        <Col>
                            <br/>
                            <table className="table table-responsive text-info">
                                <thead>
                                <tr>
                                    <th>No</th>
                                    <th style={{white: "33.3%"}}>Quantity</th>
                                    <th style={{white: "33.3%"}}>Remain</th>
                                    <th style={{white: "33.3%"}}>Price</th>
                                </tr>
                                </thead>
                                <tbody className=" table-last">
                                {isLoading === false ? (
                                    <tr>
                                        <td colSpan="4"><div className="text-center">
                                            <Spinner animation="border" variant="info"/>
                                        </div></td>
                                    </tr>

                                ) : (
                                priceList.map((e, index) => (
                                    <tr className="table-last-tr" key={index}>
                                        <td>{++index}  </td>
                                        <td>{e.quantity}  </td>
                                        <td>{e.remain_quantity}  </td>
                                        <td>{e.amount} {e.currency_name}</td>
                                    </tr>
                                )))}
                                </tbody>
                            </table>

                            <br/>
                            <br/>
                        </Col>
                    </Row>
                </Modal.Body>
                <div className="modal-footer">
                    <Button title="Close"
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => props.setShowChangeModal(false)}>
                        Close
                    </Button>

                </div>
            </Modal>
        </>
    );
}


export default ChangePrice;
