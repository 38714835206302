import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Dropdown, Row, Spinner, Table} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import WashListModel from "../washlist/WashListModel";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

function ListOrder(props) {

    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const [washlist, setWashlist] = React.useState(false);
    const [showWashListModel, setShowWashListModel] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [mainRecord, setMainRecord] = React.useState({});

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        setRecordList(props.list.data);
    },[props.list]);

    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchOrder?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getOrder?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

        setRecordId(0);
    }
    const getCart = async (id) => {
        await Axios.post(mainUrl + "getDetailRequest", {
            request_id: id,
        }, {headers}).then(res => {
            setData(res.data);
            setShowWashListModel(true);
        }).catch(function (error) {
            // notifyError(error.response.data.message);
        });

    };
    let rowNo = 1;
    return (
        <>
            <WashListModel setShowWashListModel={setShowWashListModel} showWashListModel={showWashListModel} data={data}
                           setData={setData} mainRecord={mainRecord} setMainRecord={setMainRecord}
                           setRecordList={setRecordList}
                           recordList={recordList}
            />
            <Row>
                <Col md="12">
                    <Card className="card-">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2>Order Request</h2>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <table>
                                <thead className="text-info">
                                <tr>
                                    <th>No</th>
                                    <th style={{width: "20%"}}>Customer Name</th>
                                    <th style={{width: "20%"}}>Email</th>
                                    <th style={{width: "20%"}}>Phone</th>
                                    <th style={{width: "20%"}}>Country</th>
                                    <th style={{width: "20%"}}>Status</th>
                                    <th className="text-right">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.isLoading === false ? (
                                    <tr>
                                        <td colSpan='5' className="text-center">
                                            <Spinner animation="border" variant="info"/>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {recordList.map((e, index) => (
                                            <tr key={index}>
                                                <td>{rowNo++}</td>
                                                <td style={{width: "10%"}}>{e.name} {e.last_name}</td>
                                                <td style={{width: "10%"}}>{e.email}</td>
                                                <td style={{width: "10%"}}>{e.phone}</td>
                                                <td style={{width: "10%"}}>{e.country}</td>
                                                <td style={{width: "10%"}}>
                                                    {e.status == 2 &&
                                                    <span className="badge badge-success">New Order</span>}
                                                    {e.status == 3 &&
                                                    <span className="badge badge-warning">Process</span>}
                                                    {e.status == 4 && <span className="badge badge-danger">Done</span>}
                                                </td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                            <span className="nc-icon nc-bullet-list-67"/>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Button title="Edit"
                                                                    style={{whiteSpace: "nowrap"}}
                                                                    className="btn-round" variant="light" type="Button"
                                                                    onClick={async () => {
                                                                        setMainRecord(e);
                                                                        getCart(e.id);
                                                                    }}>
                                                                <span
                                                                    className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                                View Detail Request
                                                            </Button>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </>
                                )
                                }
                                </tbody>
                            </table>
                            <div className="page-content-center">
                                {loadMore === true ? (
                                        <div className="text-center">
                                            <Spinner animation="border" variant="info"/>
                                        </div>
                                    ) :
                                    (props.list.last_page >= pageNo ?
                                        <button className="btn  bg-light text-info" title="Load More"
                                                onClick={(pageNumber => {
                                                    nextPageLoadList(pageNumber);
                                                })}>
                                            <span className="nc-icon nc-simple-add text-info"/>
                                        </button>

                                        : "")}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListOrder;
