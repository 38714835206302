import "./../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Card, CardHeader, CardTitle} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import toast from 'react-hot-toast';
import AddProduct from "./AddProduct";
import ListProduct from "./ListProduct";
import SearchProduct from "./SearchProduct";
import {EditorState} from "draft-js";

function Product() {

    const [categoryList, setCategoryList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [unitTypeList, setUnitTypeList] = useState([]);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorStateFa, setEditorStateFa] = useState(EditorState.createEmpty());
    const [editorStatePa, setEditorStatePa] = useState(EditorState.createEmpty());
    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = React.useState([]);
    const [picture, setPicture] = React.useState({'pictureFirst':"",'pictureSecond':"",'pictureThird':""});
    const [subAdminStatus, setSubAdminStatus] = useState(1);


    const [showModal, setShowModal] = useState(false);
    const [updateRecordStatus, setUpdateRecordStatus] = React.useState(false);
    const [needObject, setNeedObject] = React.useState({'project_id':0,'province_id':0,'branch_id':0,'class_id':0});
    const [searchParameter, setSearchParameter] = React.useState({
        'category_id':'',
        'sub_category_id':'',
        'product_name':''
    })
    const [searchStatus, setSearchStatus] = React.useState(false);


    useEffect(() => {
         loadData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    function getUnitType() {
        return Axios.get(mainUrl + "getUnitType",{headers});
    }
    function getCategory() {
        return Axios.get(mainUrl + "getCategoryLook",{headers});
    }
    function getSubCategory() {
        return Axios.get(mainUrl + "getSubCategoryLook",{headers});
    }
    function getProducts() {
        return Axios.get(mainUrl + "getProducts?page=1",{headers});
    }
    function getCurrency() {
        return Axios.get(mainUrl + "getCurrency",{headers});
    }
    const loadData= async()=>{
        await Promise.all([getUnitType(),getCategory(),getSubCategory(),getProducts(),getCurrency()])
            .then(function (results) {
                setUnitTypeList(results[0].data);
                setCategoryList(results[1].data);
                setSubCategoryList(results[2].data);
                setList(results[3].data);
                setCurrencyList(results[4].data);
                setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">

                <div>
                    <Row>
                        <Col md="12">
                            <Card className="card-user">
                                <CardHeader style={{backgroundColor: "#80808029"}}>
                                    <CardTitle className="mr-4 ml-4">
                                        <div className="row">
                                            <div className="ml-right">
                                                <h3> Product </h3>
                                            </div>
                                        </div>
                                    </CardTitle>
                                </CardHeader>
                                <SearchProduct subCategoryList={subCategoryList}  categoryList={categoryList} searchStatus={searchStatus} setSearchStatus={setSearchStatus} searchParameter={searchParameter} setSearchParameter={setSearchParameter}  isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList}
                                               headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>
                            </Card>
                        </Col>
                    </Row>
                    <AddProduct editorState={editorState} setEditorState={setEditorState}
                                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
                                picture={picture} setPicture={setPicture} unitTypeList={unitTypeList} subCategoryList={subCategoryList} categoryList={categoryList} setNeedObject={setNeedObject} needObject={needObject} setUpdateRecordStatus={setUpdateRecordStatus} updateRecordStatus={updateRecordStatus} subAdminStatus={subAdminStatus} setSubAdminStatus={setSubAdminStatus}  isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList} list={list}
                              headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>
                    <ListProduct currencyList={currencyList} editorState={editorState} setEditorState={setEditorState}


                                 editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                                 editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}

                                 picture={picture}  setPicture={setPicture}  searchStatus={searchStatus} setSearchStatus={setSearchStatus} searchParameter={searchParameter} setSearchParameter={setSearchParameter} setNeedObject={setNeedObject} needObject={needObject} setUpdateRecordStatus={setUpdateRecordStatus} updateRecordStatus={updateRecordStatus} subAdminStatus={subAdminStatus} setSubAdminStatus={setSubAdminStatus} isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal} setShowModal={setShowModal} setList={setList} list={list}  headers={headers} notifyError={notifyError}
                                 notifySuccess={notifySuccess}/>
                </div>
            </div>
        </>
    );


}


export default Product;
