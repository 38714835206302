import "./../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import toast from 'react-hot-toast';
import ListOrder from "./ListOrder";
import {Col, Row} from "react-bootstrap";
import {Card, CardHeader, CardTitle} from "reactstrap";
import SearchProduct from "../Product/SearchProduct";
import SearchOrder from "./SearchOrder";

function Order() {


    const [subAdminStatus, setSubAdminStatus] = useState(1);
    const [provinceList, setProvinceList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [list, setList] = React.useState([]);
    const [list1, setList1] = React.useState([]);

    const [attachFile, setAttachFiles] = React.useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [localImageOrder, setLocalImageOrder] = useState("");


    const [updateRecordStatus, setUpdateRecordStatus] = React.useState(false);
    const [needObject, setNeedObject] = React.useState({'project_id':0,'province_id':0,'branch_id':0,'class_id':0});
    const [searchParameter, setSearchParameter] = React.useState({
        'employee_id':'',
        'email':'',
        'project_id':'',
        'province_id':'',
        'branch_id':'',
        'position_id':''
    })
    const [searchStatus, setSearchStatus] = React.useState(false);
    const [requestType, setRequestType] = React.useState(false);


    useEffect(() => {
        loadData(2);
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    function getOrders(e) {
        setRequestType(e);
        return Axios.post(mainUrl + "getOrder?page=1",{"type":e},{headers});
    }
    const loadData= async(e)=>{
        await Promise.all([getOrders(e)])
            .then(function (res) {
                setList(res[0].data);
                setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">
                <div>
                    <button className="p-3 btn btn-info" onClick={()=>{loadData(2);}}>New </button>
                    <button className="p-3 btn btn-info"  onClick={()=>{loadData(3);}}>Process </button>
                    <button className="p-3 btn btn-info"  onClick={()=>{loadData(4);}}>Complete </button>
                </div>

                <div>
                    <ListOrder
                        searchParameter={searchParameter}
                        setSearchParameter={setSearchParameter}
                        setNeedObject={setNeedObject}
                        needObject={needObject}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        showModal={showModal} setShowModal={setShowModal} setList={setList} list={list}
                        headers={headers} notifyError={notifyError}
                        notifySuccess={notifySuccess}/>
                </div>

            </div>
        </>
    );


}


export default Order;
