import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {mainUrl, toBase64} from "../../common/common";
import Axios from "axios";
import toast from "react-hot-toast";

function WashLists(props) {

    const [quantity, setQuantity] = useState(0);

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const nextProcess = async (p) => {
        await Axios.post(mainUrl + "nextProcess", {
            request_id: props.data[0].request_id,
            process: p,
        }, {headers}).then(res => {
            notifySuccess('Add to Process Successfully.');
            var newData = props.recordList.filter(e => {
                if (e.id !== props.mainRecord.id) {
                    return e;
                }
            });
            props.setRecordList(newData);
            props.setShowWashListModel(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
        });
    }

    useEffect(() => {
    }, [props.data]);
    return (
        <div style={{maxHeight: '600px', overflowY: 'auto', overflowX: 'hidden'}} className="col-md-10 offset-1">
            <table className="table ">
                <thead>
                <tr style={{textAlign: 'center'}}>
                    <th>No</th>
                    <th>Product</th>
                    <th>Unit Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map((p, index) => (
                        <tr key={index} style={{textAlign: 'center'}}>

                            <td>{index + 1}</td>
                            <td>{p.product_name}</td>
                            <td>{p.unit_price} {p.currency_name}</td>
                            <td>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                paddingBottom: '5px'
                                            }}>

                                                <div
                                                    style={{
                                                        width: '100px',
                                                        textAlign: 'center',
                                                        border: '1px solid gray'
                                                    }}>
                                                    {p.quantity}
                                                </div>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </td>
                            <td>{p.total} {p.currency_name}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <div>
                <div style={{textAlign: 'right'}}>
                    {props.mainRecord.status == 2 &&
                    <div role="button" className="btn btn-primary" style={{width: '100px'}}
                         onClick={() => nextProcess(3)}>Process</div>
                    }
                    {props.mainRecord.status == 3 &&
                    <>
                        <div role="button" className="btn btn-primary" style={{width: '100px'}}
                             onClick={() => nextProcess(2)}>Cancel
                        </div>

                        <div role="button" className="btn btn-primary" style={{width: '100px'}}
                             onClick={() => nextProcess(4)}>Complete
                        </div>
                    </>
                    }
                    <div role="button" className="btn btn-warning" style={{width: '100px'}}
                         onClick={() => props.setShowWashListModel(false)}>Close
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WashLists;
