import Dashboard from "views/Dashboard.js";
import Setups from "views/Setup/Setups";
import Products from "views/Product/Product.js";

import UserPage from "views/Profile/Profile.js";
import Reports from "views/Report/Reports.js";
import Customer from "./views/Customer/Customer";
import Order from "./views/Order/Order";
import Story from "./views/Story/Story";
import Message from "./views/Message/Message";

const type = sessionStorage.getItem("type");

var dashboard= {
    path: "/dashboard",
        name: "dashboard",
    title:'',
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "",
};
var userProfile= {
    path: "/profile",
        name: "userProfile",
    title:'',
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "",
};
var product= {
    path: "/product",
        name: "Product",
    title:'',
    icon: "nc-icon nc-app",
    component: Products,
    layout: "",
};
var customer=  {
    path: "/customer",
        name: "Customer",
    title:'',
    icon: "nc-icon nc-badge",
    component: Customer,
    layout: "",
};
var order= {
    path: "/order",
        name: "Order",
    title:'',
    icon: "nc-icon nc-cart-simple",
    component: Order,
    layout: "",
};
var setup= {
    path: "/Setup",
        name: "Setup",
    title:'',
    icon: "nc-icon nc-settings",
    component: Setups,
    layout: "",
};
var story= {
    path: "/Stories",
    name: "Story",
    title:'',
    icon: "nc-icon nc-layout-11",
    component: Story,
    layout: "",
};
var report= {
    path: "/Report",
    name: "Report",
    title:'',
    icon: "nc-icon nc-chart-bar-32",
    component: Reports,
    layout: "",
};
var message= {
    path: "/Message",
    name: "Message",
    title:'',
    icon: "nc-icon nc-chart-bar-32",
    component: Message,
    layout: "",
};

var routes = [dashboard];
switch(type) {
    //Admin
    case "0dad138d82c8f678e4a5":
        routes.push(userProfile,product,customer,order,message,setup,story,report);
        break;
    // Product
    case "799a3091b121a0b3766f":
        routes.push(product);
        break;
    //Order
    case "5f86460849e109f281ea":
        routes.push(customer,order);
        break;
    // productOrder
    case "fb415b38d7ddcfda4771":
        routes.push(product,customer,order);
        break;
}
export default routes;
