// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import Axios from "axios";
import {dataURLtoFile, mainUrl, toBase64} from "../../common/common";
import {Button, Col, Dropdown, Form, FormGroup, Modal, Row, Spinner, Table} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast from "react-hot-toast";
import image from "../../assets/img/photo.png";
import ImageSelect from "./utils/imageSelect";
import {SliderPicker} from "react-color";

export default function Slide(props) {
    const [backgroundColor, setBackgroundColor] = React.useState("");
    const [image, setImage] = React.useState("");

    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [attachFiles, setAttachFiles] = useState("");
    const [downloadStatus, setDownloadStatus] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getSlide", {headers});
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };
    const addSlide = async (e) => {
        e.preventDefault();
        const f = e.target;
        let attachFile = "";
        if (f.slide_image.files[0] != null && f.slide_image.files[0] != "") {
            attachFile = await toBase64(f.slide_image.files[0]);
        }
        await Axios.post(mainUrl + "addSlide", {
            id: e.target.slideId.value,
            slide_image: attachFile,
            backgroundColor: e.target.backgroundColor.value
        }, {headers}).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setShowModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setShowModal(false);
        });
    };
    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteSliders", {
            id: recordId
        }, {headers}).then(res => {
            notifySuccess("Successfully deleted");
            const lists = res.data;
            setList(lists);
        }).catch((error) => {
            notifyError(error.response.data.message);
        });
        setRecordId(0);
        setShowDeleteModal(false);
    }

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">
                {isLoading === false ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="info"/>
                    </div>
                ) : (<div>
                        <AddItem/>
                        <TableData/>
                        <DeleteItem/>
                    </div>
                )}
            </div>
        </>
    );

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="md" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile text-gray">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3 text-gray">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round btn-info" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem() {
        const [backGround, setBackGround] = useState("");
        const handleChangeComplete = (color) => {
            setBackGround(color.hex);
        };

        const downloadFile = async () => {
            const f = document.getElementById('catId').value;
            await Axios.post(mainUrl + "getBannerImage", {
                id: f
            }, {headers}).then(res => {
                console.log(res.data);
                var filePDf = dataURLtoFile('data:image/jpeg;base64,' + res.data, 'img.jpg');
                const previewUrl = window.URL.createObjectURL(filePDf);
                const anchor = document.createElement("a");
                anchor.download = "img.jpg";
                anchor.href = URL.createObjectURL(filePDf);

                anchor.click();
                window.URL.revokeObjectURL(previewUrl);
            }).catch(function (error) {
                props.notifyError(error.response.data.message);
            });
            // setDownloadStatus(false);
        }

        return (
            <>

                <Modal show={showModal} backdrop="static" size="xl" onHide={() => setShowModal(false)}>
                    <Form onSubmit={(e) => {
                        document.getElementById("submit").disabled = true;
                        addSlide(e)
                    }}>
                        <div className="hideInput">
                            <Input type="text" className="hidden" required name="slideId" id="slideId"
                                   defaultValue={recordId}/>
                            <Input type="text" name="image" id="image" defaultValue=""/>
                        </div>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile text-info">
                               Slide
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{minHeight: "700px!important"}}>
                            <Row>
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Background Color <span className="text text-danger">*</span></label>
                                        <br/>
                                        <div style={{padding: "10px!important", border: "1px solid black!important"}}>
                                            <SliderPicker
                                                color={backGround}
                                                onChangeComplete={handleChangeComplete}
                                            />
                                        </div>
                                        <input type="hidden" name="backgroundColor" defaultValue={backGround}/>
                                    </FormGroup>
                                </Col>
                                <Col className="pr-3" md="6">
                                    <br/>
                                    <div style={{width: '100px', height: '100%', backgroundColor: backGround}}>
                                        COLOR
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <br/><br/>
                                </Col>
                            </Row>
                            <Row className="text-info">
                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Slide Image <span className="text text-danger">*</span></label>
                                        <input name="slide_image" id='slide_image' accept=".jpg" type="file"
                                               title="Image"
                                               className="form-control"/>
                                        Select .JPG file only.<br/>
                                        Maximum upload file size :1 MB<br/>
                                        (1920px) X (680px) <br/> Resize Image:  <a href="https://www.resizepixel.com/" target="_blank">resizepixel.com</a>
                                    </FormGroup>
                                    {attachFiles != "" &&
                                    <div role="button" className="btn btn-warning" onClick={()=>downloadFile()}>
                                        {downloadStatus === true ? ("Loading . . . ") : ("Download File")}
                                    </div>
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round btn-info" type="submit" id="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h2> Slide List </h2>
                                        </div>
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-info" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setShowModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-info">
                                    <tr>
                                        <th>No</th>
                                        <th>Description</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "100%",textAlign:'center'}}>
                                                <div style={{width: "400px",height:"100px",backgroundColor:e.background_color}}>
                                                    <img src={e.image} width="auto" height="100px"/>
                                                </div>
                                            </td>
                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic"
                                                                     className='outline-info dropDownClass'>
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Edit"
                                                                onClick={async () => {
                                                                    setRecordId(e.id);
                                                                    if (e.image != null && e.image != "") {
                                                                        setAttachFiles(e.image);
                                                                    }
                                                                    setBackgroundColor(e.background_color);

                                                                    await setShowModal(true);
                                                                }} className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button">
                                                            <span className="nc-icon nc-paper"/>&nbsp;&nbsp;
                                                            Edit Slide Image
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }}  className="btn-round dropdownlist dropDownClass" variant="light" type="Button"
                                                        >
                                                            <span className="nc-icon nc-simple-delete "/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
};
