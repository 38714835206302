import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Dropdown, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import toast from "react-hot-toast";
import {UserTypeList} from "../../common/UserTypeList";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

function ListProfile(props) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showBlockModal, setShowBlockModal] = React.useState(false);
    const [accountStatus, setAccountStatus] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        setRecordList(props.list.data);
    })

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteProfile", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Successfully deleted");
            const lists = res.data;
            props.setList(lists);
        }).catch((error) => {
            props.notifyError(error.response.data.message);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }
    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchProfile?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getProfile?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

        setRecordId(0);
        setShowDeleteModal(false);
    }

    const onBlockRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "blockAccount", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Account Status has been changed!");
            for (const i in props.list.data) {
                if (props.list.data[i].id == recordId) {
                    if (props.list.data[i].is_active == 1) {
                        props.list.data[i].is_active = 0;
                    } else {
                        props.list.data[i].is_active = 1;
                    }
                    break;
                }
            }
            setShowBlockModal(false);
        }).catch((error) => {
            props.notifyError(error.response.data.message);

            setShowBlockModal(false);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }

    const onForgotPassword = async (e) => {
        await Axios.post(mainUrl + "forgotPassword", {
            email: e
        }, {headers});

        setRecordId(0);
        setShowDeleteModal(false);
    }


    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function BlockItem() {
        return (
            <>
                <Modal show={showBlockModal} backdrop="static" size="lg" onHide={() => setShowBlockModal(false)}>
                    <form onSubmit={(e) => onBlockRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Col md="12">
                                    Are you sure you want to <span
                                    className="badge badge-primary">{accountStatus}</span> this Account?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowBlockModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    let rowNo = 1;
    return (
        <>
            <DeleteItem/>
            <BlockItem/>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> User Profile </h2>
                                    </div>
                                    <div className="ml-end">
                                        <Button title="Add New Record" className="btn-success" variant="primary"
                                                onClick={() => {
                                                    props.setAttachFiles(false);
                                                    props.setShowModal(true);
                                                    props.setSubAdminStatus(1);
                                                    props.setLocalImageProfile("");
                                                    sessionStorage.setItem("image", "");
                                                }}>
                                            <span className="nc-icon nc-simple-add"/>
                                        </Button>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>
                        {props.isLoading === false ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : (
                            <CardBody>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        <th>No</th>
                                        <th>image</th>
                                        <th>Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Gender</th>
                                        <th style={{whiteSpace: "nowrap"}}>User Type</th>
                                        <th style={{whiteSpace: "nowrap"}} className="text-right">Employee Status</th>
                                        <th style={{whiteSpace: "nowrap"}} className="text-right ">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {recordList.map((e, index) => (
                                        <tr key={index}>
                                            <td>{rowNo++}</td>
                                            <td>
                                                <img width="64" height="auto" src={e.image} style={{
                                                    borderRadius: "50%",
                                                    backgroundColor: "#8080805e!important"
                                                }}/>

                                            </td>

                                            <td style={{width: "20%"}}>{e.name}</td>
                                            <td style={{width: "20%"}}>{e.father_name}</td>
                                            <td style={{width: "20%"}}>{e.email}</td>
                                            <td style={{width: "20%"}}>{e.gender}</td>
                                            <td style={{width: "5%", whiteSpace: "nowrap"}}>
                                                {
                                                    UserTypeList.map((es, index) => {
                                                        if (es.value == e.user_type_id) {
                                                            return (
                                                                <span key={index} className="badge badge-primary ">{es.label}</span>
                                                            )
                                                        }

                                                    })
                                                }
                                            </td>
                                            <td style={{width: "5%", whiteSpace: "nowrap"}}>
                                                {e.is_active === 1 &&
                                                <span className="badge badge-success ">Active</span>
                                                }
                                                {e.is_active === 0 &&
                                                <span className="badge badge-default ">Non Active</span>
                                                }
                                            </td>
                                            <td>

                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>


                                                        <Button title="Edit"
                                                                style={{whiteSpace: "nowrap"}}
                                                                className="btn-round dropdownlist" variant="light" type="Button"
                                                                onClick={async () => {

                                                                    await props.setShowModal(true);
                                                                    setRecordId(e.id);
                                                                    document.getElementById('ids').value = e.id;

                                                                    document.getElementById('name').value = e.name;
                                                                    document.getElementById('father_name').value = e.father_name;
                                                                    document.getElementById('email').value = e.email;
                                                                    document.getElementById('gender_id').value = e.gender;
                                                                    document.getElementById('user_type_id').value = e.user_type_id;

                                                                    document.getElementById('status').value = e.status;
                                                                    document.getElementById('image').value = e.image;
                                                                    document.getElementById('remark').value = e.remark;
                                                                    sessionStorage.setItem("image", e.image);
                                                                    props.setLocalImageProfile(e.image);

                                                                    props.setUpdateRecordStatus(true);


                                                                }}>  <span className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                            Edit
                                                        </Button>
                                                        <Button title="Delete"
                                                                className="btn-round dropdownlist " variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    setShowDeleteModal(true);
                                                                }}>
                                                            <span
                                                                className="nc-icon nc-simple-delete text-info"/>&nbsp;&nbsp;
                                                            Delete
                                                        </Button>
                                                        <Button title="   Block & Unblock Account" style={{whiteSpace:'nowrap'}}
                                                                className="btn-round dropdownlist" variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    if (e.is_active === 0) {
                                                                        setAccountStatus('Unblock');
                                                                    } else {
                                                                        setAccountStatus('Block');
                                                                    }
                                                                    setShowBlockModal(true);
                                                                }}>

                                                            <span className="nc-icon nc-key-25 text-info"/> &nbsp;&nbsp;
                                                            Block & unBlock Account
                                                        </Button>


                                                        {/*<Button title="Forgot Password"*/}
                                                        {/*        className="btn-round dropdownlist" variant="light"*/}
                                                        {/*        type="Button" style={{whiteSpace:'nowrap'}}*/}
                                                        {/*        onClick={() => {*/}
                                                        {/*            toast.promise(*/}
                                                        {/*                onForgotPassword(e.email),*/}
                                                        {/*                {*/}
                                                        {/*                    loading: 'Sending...',*/}
                                                        {/*                    success: <b>We have e-mailed for this*/}
                                                        {/*                        account password link!!</b>,*/}
                                                        {/*                    error: <b>Server Error!</b>,*/}
                                                        {/*                }*/}
                                                        {/*            );*/}
                                                        {/*        }}>*/}
                                                        {/*    <span className="nc-icon nc-send text-info"/> &nbsp;&nbsp;*/}
                                                        {/*    Forgot Password*/}
                                                        {/*</Button>*/}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </Table>

                                <div className="page-content-center">
                                    {loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="info"/>
                                            </div>
                                        ) :
                                        (props.list.last_page >= pageNo ?
                                            <button className="btn  bg-light text-info" title="Load More"
                                                    onClick={(pageNumber => {
                                                        nextPageLoadList(pageNumber);
                                                    })}>
                                                <span className="nc-icon nc-simple-add text-info"/>
                                            </button>
                                            : "")
                                    }
                                </div>
                            </CardBody>
                        )
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListProfile;
