import Axios from "axios";
import {mainUrl} from "../../common/common";
import {CardBody} from "reactstrap";
import {Button, Col, FormGroup, Row} from "react-bootstrap";
import React, {useState} from "react";
import Select from "react-select";
import {UserTypeList} from "../../common/UserTypeList";


function SearchProfile(props) {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const [selectedOption, setSelectedOption] = useState(null);
    const searchProfile = async (e) => {
        e.preventDefault();
        const f = e.target;
        props.setSearchParameter({
            'sh_name': f.sh_name.value,
            'sh_email': f.sh_email.value,
            'sh_user_type_id': f.sh_user_type_id.value
        });
        props.setSearchStatus(true);
        const {data} = await Axios.post(mainUrl + "searchProfile?page=1",
            {
                'sh_name': f.sh_name.value,
                'sh_email': f.sh_email.value,
                'sh_user_type_id': f.sh_user_type_id.value
            }, {headers});
        const lists = data;
        props.setList(lists);
    };
    return (
        <>
            <CardBody className="mr-4 ml-4">
                <form onSubmit={(e) => searchProfile(e)}>
                    <Row>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label> Name <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Profile Name" name="sh_name" id="sh_name"
                                       placeholder="Profile Name" type="text"/>
                            </FormGroup>
                        </Col>
                        <Col className="pl-1" md="4">
                            <FormGroup>
                                <label> Email address <span
                                    className="text text-danger">*</span></label>
                                <input className="form-control" title="Email Address" name="sh_email" id="sh_email"
                                       placeholder="Email Address" type="email"/>
                            </FormGroup>
                        </Col>
                        <Col className="px-1" md="4">
                            <FormGroup>
                                <label>User Type <span
                                    className="text text-danger">*</span></label>
                                <select className="form-control" name="sh_user_type_id" id="sh_user_type_id">
                                    <option value=""></option>
                                    {
                                        UserTypeList.map((e, index) => (
                                            <option key={index} value={e.value}>{e.label}</option>
                                        ))
                                    }
                                </select>

                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <div className="ml-end">
                            <Button title="Search" className="btn btn-danger btn-round" type="submit">
                                <span className="nc-icon nc-zoom-split"/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                Search
                                &nbsp;
                            </Button>
                        </div>
                    </Row>
                </form>
            </CardBody>
        </>
    );
}


export default SearchProfile;
