import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Dropdown, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import WashListModel from "../washlist/WashListModel";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

function ListMessage(props) {

    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const [showViewModal, setShowViewModal] = React.useState(false);
    const [showWashListModel, setShowWashListModel] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [mainRecord, setMainRecord] = React.useState({});

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        setRecordList(props.list.data);
    })

    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchMessage?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getMessage?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

        setRecordId(0);
    }


    function ViewMessage() {
        return (
            <>
                <Modal show={showViewModal} backdrop="static" size="lg" onHide={() => setShowViewModal(false)}>
                         <Modal.Header>
                            <div className="modal-profile text-info">
                                <h2>View</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3 ">
                                <Col md="6">
                                    <table>
                                        <tr><td style={{width: '50%'}}>Name</td><td>{mainRecord.name}</td></tr>
                                        <tr><td  style={{width: '50%'}}>Email</td><td>{mainRecord.email}</td></tr>
                                        <tr><td  style={{width: '50%'}}>Phone</td><td>{mainRecord.phone}</td></tr>
                                        <tr><td  style={{width: '50%'}}>Subject</td><td>{mainRecord.subject}</td></tr>
                                    </table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <br/> Detail: <br/>
                                    {mainRecord.help}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowViewModal(false)}>
                                Close
                            </Button>
                        </div>
                </Modal>
            </>
        );
    }
    
    let rowNo = 1;
    return (
        <>
            <ViewMessage/>
            <Row>

                <Col md="12">
                    <Card className="card-">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2>Message </h2>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>

                        <CardBody>
                            <table>
                                <thead className="text-info">
                                <tr>
                                    <th>No</th>
                                    <th style={{width: "25%"}}>Name</th>
                                    <th style={{width: "25%"}}>Email</th>
                                    <th style={{width: "25%"}}>Phone</th>
                                    <th style={{width: "25%"}}>Subject</th>
                                    <th className="text-right">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.isLoading === false ? (
                                    <tr>
                                        <td colSpan='5' className="text-center">
                                            <Spinner animation="border" variant="info"/>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {recordList.map((e, index) => (
                                            <tr key={index}>
                                                <td>{rowNo++}</td>
                                                <td style={{width: "10%"}}>{e.name}</td>
                                                <td style={{width: "10%"}}>{e.email}</td>
                                                <td style={{width: "10%"}}>{e.phone}</td>
                                                <td style={{width: "10%"}}>{e.subject}</td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                            <span className="nc-icon nc-bullet-list-67"/>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Button title="Edit"
                                                                    style={{whiteSpace: "nowrap",width: "100%"}}
                                                                    className="btn-round" variant="light" type="Button"
                                                                    onClick={async () => {
                                                                        setMainRecord(e);
                                                                        setShowViewModal(true);
                                                                        // ViewMessage(e);
                                                                    }}>
                                                                <span
                                                                    className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                                View
                                                            </Button>

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </>
                                )
                                }
                                </tbody>
                            </table>
                            <div className="page-content-center">
                                {loadMore === true ? (
                                        <div className="text-center">
                                            <Spinner animation="border" variant="info"/>
                                        </div>
                                    ) :
                                    (props.list.last_page >= pageNo ?
                                        <button className="btn  bg-light text-info" title="Load More"
                                                onClick={(pageNumber => {
                                                    nextPageLoadList(pageNumber);
                                                })}>
                                            <span className="nc-icon nc-simple-add text-info"/>
                                        </button>

                                        : "")}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListMessage;
