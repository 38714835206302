import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Dropdown, Row, Spinner, Table} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import ChangePrice from "../Product/ChangePrice";
import AddStory from "./AddStory";
import AddDetailStory from "./AddDetailStory";
import AddProduct from "../Product/AddProduct";
import {ContentState, convertFromHTML, EditorState} from "draft-js";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

function ListStory(props) {


    const [storyDetails, setStoryDetails] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [detailShowModal, setDetailShowModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [storyId, setStoryId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        setRecordList(props.list.data);
    }, [props.list])

    const getStoryDetails = async (storyId) => {
        await Axios.post(mainUrl + "getStoryDetails", {"story_id": storyId}, {headers}).then(res => {
            setStoryId(storyId);
            if(res.data.length > 0) {
                setRecordId(res.data[0].id);
                props.setEditorState(EditorState.createWithContent(
                    ContentState.createFromBlockArray(convertFromHTML(res.data[0].detail))));
                if(res.data[0].detail_fa!=null){
                    props.setEditorStateFa(EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(res.data[0].detail_fa))));
                    props.setEditorStatePa(EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(res.data[0].detail_pa))));
                }else{
                    props.setEditorStatePa(EditorState.createEmpty());
                    props.setEditorStateFa( EditorState.createEmpty());
                }
            }else{
                setRecordId(0);
                props.setEditorState(EditorState.createEmpty());
                props.setEditorStatePa(EditorState.createEmpty());
                props.setEditorStateFa( EditorState.createEmpty());
            }
        }).catch((error) => {
            props.notifyError(error);
        });
    }

    const getStory = async (id) => {
        await Axios.post(mainUrl + "getPhoto", {
            ref_id: id,
            entity_id: 4,
        }, {headers}).then(async (res) => {
            sessionStorage.setItem("storyImage", res.data[0]);
        }).catch((error) => {
            props.notifyError(error);
        });
    }

    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchStory?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getStory?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

    }

    let rowNo = 1;
    return (
        <>
            {showModal == true &&
            <AddStory showModal={showModal} setShowModal={setShowModal}/>
            }
            {detailShowModal == true &&
            <AddDetailStory detailShowModal={detailShowModal} setDetailShowModal={setDetailShowModal}
                            editorState={props.editorState} setEditorState={props.setEditorState}
                            editorStateFa={props.editorStateFa} setEditorStateFa={props.setEditorStateFa}
                            editorStatePa={props.editorStatePa} setEditorStatePa={props.setEditorStatePa}
                            recordId={recordId}
                            storyId={storyId}
            />
            }
            <Row>
                <Col md="12">
                    <Card className="card-">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2>Story List</h2>
                                    </div>
                                </div>
                                <div className="ml-end">
                                    <Button title="Add New Record" className="btn-info" variant=""
                                            onClick={async() => {
                                                sessionStorage.removeItem("storyImage");
                                               await setShowModal(true);
                                                document.getElementById('storyIds').value = 0;
                                            }}>
                                        <span className="nc-icon nc-simple-add"/>
                                    </Button>
                                </div>

                            </CardTitle>
                        </CardHeader>

                        <CardBody>
                            <Table>
                                <thead className="text-info">
                                <tr>
                                    <th>No</th>
                                    <th style={{width: "20%"}}>Title</th>
                                    <th style={{width: "80%"}}>Description</th>
                                    <th className="text-right">Action</th>
                                </tr>
                                </thead>

                                {props.isLoading === false ? (<tbody>
                                    <tr>
                                        <td colSpan='5' className="text-center">
                                            <Spinner animation="bStory" variant="info"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                ) : (
                                    <tbody>
                                    {
                                        recordList.map((e, index) => (
                                            <tr key={index}>
                                                <td>{rowNo++}</td>
                                                <td style={{width: "10%"}}>{e.title}</td>
                                                <td style={{width: "10%"}}>{e.description}</td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                                            <span className="nc-icon nc-bullet-list-67"/>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Button title="View Detail" style={{whiteSpace: "nowrap"}}
                                                                    className="btn-round" variant="light" type="Button"
                                                                    onClick={async () => {
                                                                        await getStory(e.id);
                                                                        await setShowModal(true);
                                                                        document.getElementById('storyIds').value = e.id;
                                                                        document.getElementById('title').value = e.title;
                                                                        document.getElementById('description').value = e.description;

                                                                        document.getElementById('title_fa').value = e.title_fa;
                                                                        document.getElementById('description_fa').value = e.description_fa;

                                                                        document.getElementById('title_pa').value = e.title_pa;
                                                                        document.getElementById('description_pa').value = e.description_pa;

                                                                    }}>
                                                                <span
                                                                    className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                                View & Update
                                                            </Button>
                                                            <Button title="View Detail" style={{whiteSpace: "nowrap"}}
                                                                    className="btn-round" variant="light" type="Button"
                                                                    onClick={async () => {
                                                                        await setStoryId(e.id);
                                                                        await getStoryDetails(e.id);
                                                                        await setDetailShowModal(true);
                                                                    }}>
                                                                <span
                                                                    className="nc-icon nc-paper text-info"/>&nbsp;&nbsp;
                                                                Add Detail
                                                            </Button>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                )
                                }
                            </Table>
                            <div className="page-content-center">
                                {
                                    loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="bStory" variant="info"/>
                                            </div>
                                        ) :
                                        (
                                            props.list.last_page >= pageNo ?
                                                <button className="btn  bg-light text-info" title="Load More"
                                                        onClick={(pageNumber => {
                                                            nextPageLoadList(pageNumber);
                                                        })}>
                                                    <span className="nc-icon nc-simple-add text-info"/>
                                                </button>
                                                : ""
                                        )
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListStory;
