import "./../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import Axios from "axios";
import {mainUrl} from "../../common/common";
import toast from 'react-hot-toast';
import ListMessage from "./ListMessage";

function Message() {


    const [subAdminStatus, setSubAdminStatus] = useState(1);
    const [provinceList, setProvinceList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [list, setList] = React.useState([]);
    const [attachFile, setAttachFiles] = React.useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [localImageMessage, setLocalImageMessage] = useState("");


    const [updateRecordStatus, setUpdateRecordStatus] = React.useState(false);
    const [needObject, setNeedObject] = React.useState({'project_id':0,'province_id':0,'branch_id':0,'class_id':0});
    const [searchParameter, setSearchParameter] = React.useState({
        'employee_id':'',
        'email':'',
        'project_id':'',
        'province_id':'',
        'branch_id':'',
        'position_id':''
    })
    const [searchStatus, setSearchStatus] = React.useState(false);


    useEffect(() => {
        loadData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    function getMessages() {
        return Axios.get(mainUrl + "getMessage?page=1",{headers});
    }
    const loadData= async()=>{
        await Promise.all([getMessages()])
            .then(function (res) {
                setList(res[0].data);
                setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });;
    }


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">

                <div>
                      <ListMessage
                          searchParameter={searchParameter}
                          setSearchParameter={setSearchParameter}
                          setNeedObject={setNeedObject}
                          needObject={needObject}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          showModal={showModal} setShowModal={setShowModal} setList={setList} list={list}  headers={headers} notifyError={notifyError}
                                 notifySuccess={notifySuccess}/>
                </div>

            </div>
        </>
    );


}


export default Message;
