// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import Axios from "axios";
import {mainUrl} from "../../common/common";
import {Button, Col, Dropdown, Form, FormGroup, Modal, Row, Spinner, Table} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast from "react-hot-toast";

export default function Currency(headers) {
    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        const {data} = await Axios.get(mainUrl + "getCurrency", headers);
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };
    const addCurrency = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "addCurrency", {
            id: e.target.id.value,
            name: e.target.name.value,
            name_fa: e.target.name_fa.value,
            name_pa: e.target.name_pa.value
        }, headers).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setShowModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setShowModal(false);
        });
    };
    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteCurrency", {
            id: recordId
        }, headers).then(res => {
            notifySuccess("Successfully deleted");
            const lists = res.data;
            setList(lists);
        }).catch((error) => {
            notifyError(error.response.data.message);

        });
        setRecordId(0);
        setShowDeleteModal(false);
    }

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">
                {isLoading === false ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="info"/>
                    </div>
                ) : (<div>
                        <AddItem/>
                        <TableData/>
                        <DeleteItem/>
                    </div>
                )}
            </div>
        </>
    );

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="md" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => {
                        onDeleteRecord(e)
                    }}>
                        <Modal.Header>
                            <div className="modal-profile text-gray">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3 text-gray">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round btn-info" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem() {
        return (
            <>
                <Modal show={showModal} backdrop="static" size="md" onHide={() => setShowModal(false)}>
                    <Form onSubmit={(e) => {
                        document.getElementById("submit").disabled = true;
                        addCurrency(e)
                    }}>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile text-info">
                                Add New  Currency 
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3">
                                <Input hidden={true} required name="id" id="id" value="0" type="hidden"/>

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Currency  name <span className="text text-danger">*</span></label>
                                        <Input required name="Currency Name" title="Currency Name" id="name"
                                               placeholder="name" type="text"/>
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row className="pr-3">

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Currency  name Farsi<span className="text text-danger">*</span></label>
                                        <Input required name="Currency Name" title="Currency Name" id="name_fa"
                                               placeholder="name" type="text"/>
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row className="pr-3">

                                <Col className="pr-3" md="6">
                                    <FormGroup>
                                        <label>Currency  name Pashto<span className="text text-danger">*</span></label>
                                        <Input required name="Currency Name" title="Currency Name" id="name_pa"
                                               placeholder="name" type="text"/>
                                    </FormGroup>
                                </Col>

                            </Row>

                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round btn-info" id="submit" type="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h2> Currency  List </h2>
                                        </div>
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-info" variant="primary"
                                                    onClick={() => {
                                                        setRecordId(0);
                                                        setShowModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-info">
                                    <tr>
                                        <th>No</th>
                                        <th>Currency  Name</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "100%"}}>{e.name}</td>
                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle  id="dropdown-basic" className='outline-info dropDownClass'>
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Edit"
                                                                onClick={async () => {
                                                                    await setShowModal(true);
                                                                    document.getElementById('name').value = e.name;
                                                                    document.getElementById('name_fa').value = e.name_fa;
                                                                    document.getElementById('name_pa').value = e.name_pa;
                                                                    document.getElementById('id').value = e.id;
                                                                }}  className="btn-round dropdownlist dropDownClass" variant="light" type="Button" >
                                                            <span className="nc-icon nc-paper"/>&nbsp;&nbsp;
                                                            Edit
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }}  className="btn-round dropdownlist dropDownClass" variant="light" type="Button"
                                                        >
                                                            <span className="nc-icon nc-simple-delete "/>&nbsp;&nbsp;
                                                            Delete Record
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
};
