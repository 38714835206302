// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Card, Col, Dropdown, Form, FormGroup, Modal, Row, Spinner, Table} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import Category from "../category/Category";
import AboutUs from "./AboutUs";
import Axios from "axios";
import {mainUrl} from "../../../common/common";
import {ContentState, convertFromHTML, EditorState} from "draft-js";
import AddDetailStory from "../../Story/AddDetailStory";
import TermsService from "./TermsService";
import PrivacyPolicy from "./PrivacyPolicy";
import FAQ from "./FAQ";
import ReturnPolicy from "./ReturnPolicy";
import ShippingPolicy from "./ShippingPolicy";
import PaymentPolicy from "./PaymentPolicy";
import ExpressDelivery from "./ExpressDelivery";
import SitePayment from "./SitePayment";
import BackGuarantee from "./BackGuarantee";
import GuaranteeGoods from "./GuaranteeGoods";


export default function Other() {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorStateFa, setEditorStateFa] = useState(EditorState.createEmpty());
    const [editorStatePa, setEditorStatePa] = useState(EditorState.createEmpty());
    const [aboutShowModal, setAboutShowModal] = useState(false);
    const [termsServiceModal, setTermsServiceModal] = useState(false);
    const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);
    const [fAQModal, setFAQModal] = useState(false);
    const [returnPolicyModal, setReturnPolicyModal] = useState(false);
    const [shippingPolicyModal, setShippingPolicyModal] = useState(false);
    const [paymentPolicyModal, setPaymentPolicyModal] = useState(false);

    const [aboutUs, setAboutUs] = useState(true);
    const [termsService, setTermsService] = useState(true);
    const [fAQ, setFAQ] = useState(true);
    const [privacyPolicy, setPrivacyPolicy] = useState(true);
    const [returnPolicy, setReturnPolicy] = useState(true);
    const [shippingPolicy, setShippingPolicy] = useState(true);
    const [paymentPolicy, setPaymentPolicy] = useState(true);

    const [expressDelivery,setExpressDelivery] = useState(true);
    const [sitePayment, setSitePayment] = useState(true);
    const [backGuarantee, setBackGuarantee] = useState(true);
    const [guaranteeGoods, setGuaranteeGoods] = useState(true);


    const [expressDeliveryModal, setExpressDeliveryModal] = useState(false);
    const [sitePaymentModal, setSitePaymentModal] = useState(false);
    const [backGuaranteeModal, setBackGuaranteeModal] = useState(false);
    const [guaranteeGoodsModal, setGuaranteeGoodsModal] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const reSetClass = () => {
        setAboutUs(false);
        setTermsService(false);
        setFAQ(false);
        setPrivacyPolicy(false);
        setReturnPolicy(false);
        setShippingPolicy(false);
        setPaymentPolicy(false);

        setExpressDelivery(false);
        setSitePayment(false);
        setBackGuarantee(false);
        setGuaranteeGoods(false);

        document.getElementById('shippingPolicy').classList.remove("tab-active");
        document.getElementById('aboutUs').classList.remove("tab-active");
        document.getElementById('termsService').classList.remove("tab-active");
        document.getElementById('fAQ').classList.remove("tab-active");
        document.getElementById('privacyPolicy').classList.remove("tab-active");
        document.getElementById('returnPolicy').classList.remove("tab-active");
        document.getElementById('paymentPolicy').classList.remove("tab-active");

        document.getElementById('expressDelivery').classList.remove("tab-active");
        document.getElementById('sitePayment').classList.remove("tab-active");
        document.getElementById('backGuarantee').classList.remove("tab-active");
        document.getElementById('guaranteeGoods').classList.remove("tab-active");
    }

    const getPage = async (page) => {
        await Axios.post(mainUrl + "getPage", {'page_name': page}, {headers}).then(res => {
                if (res.data != null) {
                    if (res.data.data != null) {
                        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res.data.data))));
                    } else {
                        setEditorState(EditorState.createEmpty());
                    }
                    if (res.data.data_pa != null) {
                        setEditorStatePa(EditorState.createWithContent(
                            ContentState.createFromBlockArray(convertFromHTML(res.data.data_pa))));
                    } else {
                        setEditorStatePa(EditorState.createEmpty());
                    }
                    if (res.data.data_fa != null) {
                        setEditorStateFa(EditorState.createWithContent(
                            ContentState.createFromBlockArray(convertFromHTML(res.data.data_fa))));
                    } else {
                        setEditorStateFa(EditorState.createEmpty());
                    }
                }

            }
        )
            .catch((error) => {
                // props.notifyError(error);
            });
    }
    return (
        <>
            {aboutUs == true &&
            <AboutUs
                aboutShowModal={aboutShowModal} setAboutShowModal={setAboutShowModal}
                headers={headers} editorState={editorState} setEditorState={setEditorState}

                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}
            {termsService == true &&
            <TermsService
                setTermsServiceModal={setTermsServiceModal} termsServiceModal={termsServiceModal}
                headers={headers} editorState={editorState} setEditorState={setEditorState}

                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}
            {privacyPolicy == true &&
            <PrivacyPolicy
                setPrivacyPolicyModal={setPrivacyPolicyModal} privacyPolicyModal={privacyPolicyModal}
                headers={headers} editorState={editorState} setEditorState={setEditorState}

                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}
            {fAQ == true &&
            <FAQ
                setFAQModal={setFAQModal} fAQModal={fAQModal}
                headers={headers}
                editorState={editorState} setEditorState={setEditorState}
                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}
            {returnPolicy == true &&
            <ReturnPolicy
                setReturnPolicyModal={setReturnPolicyModal} returnPolicyModal={returnPolicyModal}
                headers={headers}
                editorState={editorState} setEditorState={setEditorState}
                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}
            {shippingPolicy == true &&
            <ShippingPolicy
                setShippingPolicyModal={setShippingPolicyModal} shippingPolicyModal={shippingPolicyModal}
                headers={headers}
                editorState={editorState} setEditorState={setEditorState}
                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}
            {paymentPolicy == true &&
            <PaymentPolicy
                setPaymentPolicyModal={setPaymentPolicyModal} paymentPolicyModal={paymentPolicyModal}
                headers={headers}
                editorState={editorState} setEditorState={setEditorState}
                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}

            {expressDelivery == true &&
            <ExpressDelivery
                setExpressDeliveryModal={setExpressDeliveryModal} expressDeliveryModal={expressDeliveryModal}
                headers={headers}
                editorState={editorState} setEditorState={setEditorState}
                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}
            {sitePayment == true &&
            <SitePayment
                setSitePaymentModal={setSitePaymentModal} sitePaymentModal={sitePaymentModal}
                headers={headers}
                editorState={editorState} setEditorState={setEditorState}
                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}
            {backGuarantee == true &&
            <BackGuarantee
                setBackGuaranteeModal={setBackGuaranteeModal} backGuaranteeModal={backGuaranteeModal}
                headers={headers}
                editorState={editorState} setEditorState={setEditorState}
                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}
            {guaranteeGoods == true &&
            <GuaranteeGoods
                setGuaranteeGoodsModal={setGuaranteeGoodsModal} guaranteeGoodsModal={guaranteeGoodsModal}
                headers={headers}
                editorState={editorState} setEditorState={setEditorState}
                editorStateFa={editorStateFa} setEditorStateFa={setEditorStateFa}
                editorStatePa={editorStatePa} setEditorStatePa={setEditorStatePa}
            />}

            <div className="content">

                <Row>
                    <Col md="12">
                        <Card className=" ">
                            <CardHeader style={{backgroundColor: "#80808029"}}>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h3> Other </h3>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>

                            <CardBody className="all-icons mr-4 ml-4">
                                <div>
                                    <div className="row col-md-12" style={{display: "inline-flex"}}>
                                        <div className="col-md-1 tab" id="aboutUs" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('about');
                                                 setAboutShowModal(true);
                                                 document.getElementById('aboutUs').classList.add("tab-active");
                                                 setAboutUs(true);
                                             }}>
                                            About Us
                                        </div>
                                        <div className="col-md-1 tab" id="termsService" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('terms_of_service');
                                                 setTermsServiceModal(true);
                                                 document.getElementById('termsService').classList.add("tab-active");
                                                 setTermsService(true);
                                             }}>
                                            Terms of Service
                                        </div>
                                        <div className="col-md-1 tab" id="privacyPolicy" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('privacy_policy');
                                                 setPrivacyPolicyModal(true);
                                                 document.getElementById('privacyPolicy').classList.add("tab-active");
                                                 setPrivacyPolicy(true);
                                             }}>
                                            Privacy Policy
                                        </div>

                                        <div className="col-md-1 tab" id="fAQ" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('fAQ');
                                                 setFAQModal(true);
                                                 document.getElementById('fAQ').classList.add("tab-active");
                                                 setFAQ(true);
                                             }}>
                                            FAQ
                                        </div>
                                        <div className="col-md-1 tab" id="returnPolicy" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('return_policy');
                                                 setReturnPolicyModal(true);
                                                 document.getElementById('returnPolicy').classList.add("tab-active");
                                                 setReturnPolicy(true);
                                             }}>
                                            Return Policy
                                        </div>
                                        <div className="col-md-1 tab" id="shippingPolicy" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('shipping_policy');
                                                 setShippingPolicyModal(true);
                                                 document.getElementById('shippingPolicy').classList.add("tab-active");
                                                 setShippingPolicy(true);
                                             }}>
                                            Shipping Policy
                                        </div>
                                        <div className="col-md-1 tab" id="paymentPolicy" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('payment_policy');
                                                 setPaymentPolicyModal(true);
                                                 document.getElementById('paymentPolicy').classList.add("tab-active");
                                                 setPaymentPolicy(true);
                                             }}>
                                            Payment Policy
                                        </div>


                                        <div className="col-md-1 tab" id="expressDelivery" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('express_delivery');
                                                 setExpressDeliveryModal(true);
                                                 document.getElementById('expressDelivery').classList.add("tab-active");
                                                 setExpressDelivery(true);
                                             }}>
                                            Express delivery
                                        </div>

                                        <div className="col-md-1 tab" id="sitePayment" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('site_payment');
                                                 setSitePaymentModal(true);
                                                 document.getElementById('sitePayment').classList.add("tab-active");
                                                 setSitePayment(true);
                                             }}>
                                            On-site payment
                                        </div>

                                        <div className="col-md-1 tab" id="backGuarantee" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('back_guarantee');
                                                 setBackGuaranteeModal(true);
                                                 document.getElementById('backGuarantee').classList.add("tab-active");
                                                 setBackGuarantee(true);
                                             }}>
                                            7 days money back guarantee
                                        </div>

                                        <div className="col-md-1 tab" id="guaranteeGoods" role="button"
                                             onClick={async () => {
                                                 await reSetClass();
                                                 await getPage('guarantee_goods');
                                                 setGuaranteeGoodsModal(true);
                                                 document.getElementById('guaranteeGoods').classList.add("tab-active");
                                                 setGuaranteeGoods(true);
                                             }}>
                                            Guarantee the originality of the goods
                                        </div>
                                    </div>
                                    <div className="row">
                                    </div>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}
;
