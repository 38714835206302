import Axios from "axios";
import {mainUrl} from "../../common/common";
import React, {forwardRef, useRef, useEffect} from "react";
import {Button, Card, CardHeader, CardTitle, Col, Row} from "reactstrap";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";
import {Spinner} from "react-bootstrap";
import {useDownloadExcel} from "react-export-table-to-excel";

// import './Report.css';
function TableData(props) {

    const tableRef = useRef();
    const tableRefs = useRef();

    const {onDownload} = useDownloadExcel({
        currentTableRef: tableRefs.current,
        filename: 'Report',
        sheet: 'Report'
    });

    const {onDownloads} = useDownloadExcel({
        currentTableRef: tableRefs.current,
        filename: 'Reports',
        sheet: 'Reports'
    });
    const ComponentToPrint = forwardRef((prop, reference) => (
        <div className="  card table table-reference" key='12sdew323' ref={reference}>
            <div key="123asd2" className="" ref={tableRefs}>
                <table className=" table p-2">
                    <thead className="text-primary">
                    <tr>
                        <td colSpan="10" className="text-primary content-center h3 p-5"
                            style={{textAlign: 'center'}}>
                            Order Report
                        </td>
                    </tr>
                    <tr>
                        <th>No</th>
                        <th>name</th>
                        <th>last_name</th>
                        <th>email</th>
                        <th>country</th>
                        <th>product_name</th>
                        <th>unit_price</th>
                        <th>quantity</th>
                        <th>currency</th>
                        <th>total</th>
                    </tr>
                    </thead>
                    <tbody>

                    {props.list.map((e, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{e.name}</td>
                            <td>{e.last_name}</td>
                            <td>{e.email}</td>
                            <td>{e.country}</td>
                            <td>{e.product_name}</td>
                            <td>{e.unit_price}</td>
                            <td>{e.quantity}</td>
                            <td>{e.currency}</td>
                            <td>{e.total}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    ));
    const ComponentToPrint1 = forwardRef((prop, reference) => (
        <div className=" card table table-reference p-4" key='13' ref={reference}>
            <div key="123asd2" className="" ref={tableRefs}>
                <table className="table" style={{verticalAlign: 'top'}}>
                    <thead className="text-primary">
                    <tr>
                        <td colSpan="10" className="text-primary content-center h3 p-5"
                            style={{textAlign: 'center'}}>
                            Brief Category Report
                        </td>
                    </tr>

                    <tr>
                        <th>No</th>
                        <th>Category</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.list.map((e, index) => (
                        <tr key={index}>
                            <td style={{verticalAlign: 'top'}}>{index + 1}</td>
                            <td style={{verticalAlign: 'top'}}>{e.category_name}</td>
                            <td>
                                <table className="table ">
                                    <tr>
                                        <th style={{width: '30%'}}>Sub Category</th>
                                        <th>Product</th>
                                    </tr>
                                    {e.sub_category.map((s, inex) => (

                                        <tr key={inex} className="border">
                                            <td style={{verticalAlign: 'top'}}>
                                                <span
                                                    className=" ">{s.sub_category_name}<br/></span>
                                            </td>
                                            {(s.product.length > 0) &&
                                            <td>
                                                <tr>
                                                    <th style={{width: '20%'}}>Product Name</th>
                                                    <th style={{width: '20%'}}>Quantity</th>
                                                    <th style={{width: '20%'}}>Remain</th>
                                                    <th style={{width: '20%'}}>Price</th>
                                                </tr>
                                                {s.product.map((p, pinex) => (
                                                    <tr key={pinex}>
                                                        <td>{p.name}</td>
                                                        <td>{p.total_quantity}</td>
                                                        <td>{p.remain_quantity}</td>
                                                        <td>{p.price}</td>
                                                    </tr>
                                                ))}
                                            </td>
                                            }
                                        </tr>

                                    ))}
                                </table>
                            </td>

                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    ));

    const ComponentToPrint2 = forwardRef((prop, reference) => (
        <div className=" card table table-reference p-4" key='1io9ooioii3' ref={reference}>
            <div key="123asd2" className="" ref={tableRefs}>
                {props.list.map((main, MainIndex) => (
                    <div key={MainIndex}>
                        <table className="table" style={{verticalAlign: 'top'}}>
                            <thead className="text-primary">
                            <tr>
                                <td colSpan="6" className="text-primary content-center h3 p-5"
                                    style={{textAlign: 'center'}}>
                                    Customer Report
                                </td>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Address</th>
                                <th>country</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td style={{verticalAlign: 'top'}}>{main.customer.name}</td>
                                <td style={{verticalAlign: 'top'}}>{main.customer.last_name}</td>
                                <td style={{verticalAlign: 'top'}}>{main.customer.email}</td>
                                <td style={{verticalAlign: 'top'}}>{main.customer.phone}</td>
                                <td style={{verticalAlign: 'top'}}>{main.customer.address}</td>
                                <td style={{verticalAlign: 'top'}}>{main.customer.country}</td>
                            </tr>
                            </tbody>
                        </table>
                        {main.order.map((e, index) => (
                            <table key={index} className="table" style={{verticalAlign: 'top'}}>
                                <thead className="text-primary">
                                <tr>
                                    <th colSpan="6">
                                        <span className="badge badge-warning">{index + 1}</span> Order Date :
                                        <span className="badge badge-primary">{e.order.created_at} </span> ,
                                        Status :
                                        {e.order.status == 2 && <span className="badge badge-danger">Pending</span>}
                                        {e.order.status == 3 && <span className="badge badge-success">Process</span>}
                                        {e.order.status == 4 && <span className="badge badge-info">Complete</span>}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>No</td>
                                    <td>Product Name</td>
                                    <td>Quantity</td>
                                    <td>Unit Price</td>
                                    <td>Total</td>
                                </tr>

                                {e.detail.map((d, inx) => (
                                    <tr key={inx}>
                                        <td>{inx + 1}</td>
                                        <td>{d.product_name}</td>
                                        <td>{d.quantity} {d.unit_type}</td>
                                        <td>{d.unit_price} {d.currency_name}</td>
                                        <td>{d.total}  {d.currency_name}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ))}
                    </div>
                ))}

            </div>
        </div>
    ));


    let componentRef = useRef();
    //
    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: 'A4'
        // format: [10,12]
    };
    // let rowNumber = 1;
    return (
        <>

            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> Result Report </h2>
                                    </div>
                                    <div className="ml-end">
                                        {/*<ReactToPdf targetRef={tableRef}  filename="div-blue.pdf" options={options} x={0} y={0} scale={0.75}>*/}
                                        {/*    {({toPdf}) => (*/}
                                        {/*        <button onClick={toPdf}>Generate pdf</button>*/}
                                        {/*    )}*/}
                                        {/*</ReactToPdf>*/}

                                        {(props.reportType == 3) &&
                                        <button className="btn btn-warning" onClick={onDownload}> Export excel</button>
                                        }
                                        {(props.reportType < 3) &&
                                        <button className="btn btn-warning" onClick={onDownload}> Export excel</button>
                                        }
                                        {/*<button className="btn btn-warning" onClick={onDownload}> Export excel</button>*/}

                                        <ReactToPrint
                                            trigger={() => <button title="Print Report"
                                                                   className=" btn btn-dark">Print</button>}
                                            content={() => componentRef.current}
                                        />
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>


                        {(props.isLoading == 1 && props.reportType == 1) ?
                            <ComponentToPrint1 ref={componentRef}/> : ""
                        }


                        {(props.isLoading == 1 && props.reportType == 2) ?
                            <ComponentToPrint ref={componentRef}/> : ""
                        }


                        {(props.isLoading == 1 && props.reportType == 3) ?
                            <ComponentToPrint2 ref={componentRef}/> : ""
                        }

                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default TableData;
